/*Primary Colors*/
/*Accent Colors*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap");
.university-0 {
  background-color: #51499C !important;
}
.university-0::after {
  content: "#51499C";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-10 {
  background-color: #49428c !important;
}
.university-10::after {
  content: "#49428c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-20 {
  background-color: #413a7d !important;
}
.university-20::after {
  content: "#413a7d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-30 {
  background-color: #39336d !important;
}
.university-30::after {
  content: "#39336d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-40 {
  background-color: #312c5e !important;
}
.university-40::after {
  content: "#312c5e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-50 {
  background-color: #29254e !important;
}
.university-50::after {
  content: "#29254e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-60 {
  background-color: #201d3e !important;
}
.university-60::after {
  content: "#201d3e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-70 {
  background-color: #18162f !important;
}
.university-70::after {
  content: "#18162f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-80 {
  background-color: #100f1f !important;
}
.university-80::after {
  content: "#100f1f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-90 {
  background-color: #080710 !important;
}
.university-90::after {
  content: "#080710";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-100 {
  background-color: #000000 !important;
}
.university-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-0 {
  background-color: #51499c !important;
}
.university-lite-0::after {
  content: "#51499c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-10 {
  background-color: #625ba6 !important;
}
.university-lite-10::after {
  content: "#625ba6";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-20 {
  background-color: #746db0 !important;
}
.university-lite-20::after {
  content: "#746db0";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-30 {
  background-color: #8580ba !important;
}
.university-lite-30::after {
  content: "#8580ba";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-40 {
  background-color: #9792c4 !important;
}
.university-lite-40::after {
  content: "#9792c4";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-50 {
  background-color: #a8a4ce !important;
}
.university-lite-50::after {
  content: "#a8a4ce";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-60 {
  background-color: #b9b6d7 !important;
}
.university-lite-60::after {
  content: "#b9b6d7";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-70 {
  background-color: #cbc8e1 !important;
}
.university-lite-70::after {
  content: "#cbc8e1";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-80 {
  background-color: #dcdbeb !important;
}
.university-lite-80::after {
  content: "#dcdbeb";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-90 {
  background-color: #eeedf5 !important;
}
.university-lite-90::after {
  content: "#eeedf5";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-100 {
  background-color: #ffffff !important;
}
.university-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-0 {
  background-color: #5cae48 !important;
}
.primaryGreen-0::after {
  content: "#5cae48";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-10 {
  background-color: #539d41 !important;
}
.primaryGreen-10::after {
  content: "#539d41";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-20 {
  background-color: #4a8b3a !important;
}
.primaryGreen-20::after {
  content: "#4a8b3a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-30 {
  background-color: #407a32 !important;
}
.primaryGreen-30::after {
  content: "#407a32";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-40 {
  background-color: #37682b !important;
}
.primaryGreen-40::after {
  content: "#37682b";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-50 {
  background-color: #2e5724 !important;
}
.primaryGreen-50::after {
  content: "#2e5724";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-60 {
  background-color: #25461d !important;
}
.primaryGreen-60::after {
  content: "#25461d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-70 {
  background-color: #1c3416 !important;
}
.primaryGreen-70::after {
  content: "#1c3416";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-80 {
  background-color: #12230e !important;
}
.primaryGreen-80::after {
  content: "#12230e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-90 {
  background-color: #091107 !important;
}
.primaryGreen-90::after {
  content: "#091107";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-100 {
  background-color: #000000 !important;
}
.primaryGreen-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-0 {
  background-color: #5cae48 !important;
}
.primaryGreen-lite-0::after {
  content: "#5cae48";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-10 {
  background-color: #6cb65a !important;
}
.primaryGreen-lite-10::after {
  content: "#6cb65a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-20 {
  background-color: #7dbe6d !important;
}
.primaryGreen-lite-20::after {
  content: "#7dbe6d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-30 {
  background-color: #8dc67f !important;
}
.primaryGreen-lite-30::after {
  content: "#8dc67f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-40 {
  background-color: #9dce91 !important;
}
.primaryGreen-lite-40::after {
  content: "#9dce91";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-50 {
  background-color: #aed7a4 !important;
}
.primaryGreen-lite-50::after {
  content: "#aed7a4";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-60 {
  background-color: #bedfb6 !important;
}
.primaryGreen-lite-60::after {
  content: "#bedfb6";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-70 {
  background-color: #cee7c8 !important;
}
.primaryGreen-lite-70::after {
  content: "#cee7c8";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-80 {
  background-color: #deefda !important;
}
.primaryGreen-lite-80::after {
  content: "#deefda";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-90 {
  background-color: #eff7ed !important;
}
.primaryGreen-lite-90::after {
  content: "#eff7ed";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-100 {
  background-color: #ffffff !important;
}
.primaryGreen-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-0 {
  background-color: #26a7df !important;
}
.schools-0::after {
  content: "#26a7df";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-10 {
  background-color: #2296c9 !important;
}
.schools-10::after {
  content: "#2296c9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-20 {
  background-color: #1e86b2 !important;
}
.schools-20::after {
  content: "#1e86b2";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-30 {
  background-color: #1b759c !important;
}
.schools-30::after {
  content: "#1b759c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-40 {
  background-color: #176486 !important;
}
.schools-40::after {
  content: "#176486";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-50 {
  background-color: #135470 !important;
}
.schools-50::after {
  content: "#135470";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-60 {
  background-color: #0f4359 !important;
}
.schools-60::after {
  content: "#0f4359";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-70 {
  background-color: #0b3243 !important;
}
.schools-70::after {
  content: "#0b3243";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-80 {
  background-color: #08212d !important;
}
.schools-80::after {
  content: "#08212d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-90 {
  background-color: #041116 !important;
}
.schools-90::after {
  content: "#041116";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-100 {
  background-color: #000000 !important;
}
.schools-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-0 {
  background-color: #26a7df !important;
}
.schools-lite-0::after {
  content: "#26a7df";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-10 {
  background-color: #3cb0e2 !important;
}
.schools-lite-10::after {
  content: "#3cb0e2";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-20 {
  background-color: #51b9e5 !important;
}
.schools-lite-20::after {
  content: "#51b9e5";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-30 {
  background-color: #67c1e9 !important;
}
.schools-lite-30::after {
  content: "#67c1e9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-40 {
  background-color: #7dcaec !important;
}
.schools-lite-40::after {
  content: "#7dcaec";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-50 {
  background-color: #93d3ef !important;
}
.schools-lite-50::after {
  content: "#93d3ef";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-60 {
  background-color: #a8dcf2 !important;
}
.schools-lite-60::after {
  content: "#a8dcf2";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-70 {
  background-color: #bee5f5 !important;
}
.schools-lite-70::after {
  content: "#bee5f5";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-80 {
  background-color: #d4edf9 !important;
}
.schools-lite-80::after {
  content: "#d4edf9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-90 {
  background-color: #e9f6fc !important;
}
.schools-lite-90::after {
  content: "#e9f6fc";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-100 {
  background-color: #ffffff !important;
}
.schools-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-0 {
  background-color: #003457 !important;
}
.primaryDarkBlue-0::after {
  content: "#003457";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-10 {
  background-color: #002f4e !important;
}
.primaryDarkBlue-10::after {
  content: "#002f4e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-20 {
  background-color: #002a46 !important;
}
.primaryDarkBlue-20::after {
  content: "#002a46";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-30 {
  background-color: #00243d !important;
}
.primaryDarkBlue-30::after {
  content: "#00243d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-40 {
  background-color: #001f34 !important;
}
.primaryDarkBlue-40::after {
  content: "#001f34";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-50 {
  background-color: #001a2c !important;
}
.primaryDarkBlue-50::after {
  content: "#001a2c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-60 {
  background-color: #001523 !important;
}
.primaryDarkBlue-60::after {
  content: "#001523";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-70 {
  background-color: #00101a !important;
}
.primaryDarkBlue-70::after {
  content: "#00101a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-80 {
  background-color: #000a11 !important;
}
.primaryDarkBlue-80::after {
  content: "#000a11";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-90 {
  background-color: #000509 !important;
}
.primaryDarkBlue-90::after {
  content: "#000509";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-100 {
  background-color: #000000 !important;
}
.primaryDarkBlue-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-0 {
  background-color: #003457 !important;
}
.primaryDarkBlue-lite-0::after {
  content: "#003457";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-10 {
  background-color: #1a4868 !important;
}
.primaryDarkBlue-lite-10::after {
  content: "#1a4868";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-20 {
  background-color: #335d79 !important;
}
.primaryDarkBlue-lite-20::after {
  content: "#335d79";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-30 {
  background-color: #4d7189 !important;
}
.primaryDarkBlue-lite-30::after {
  content: "#4d7189";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-40 {
  background-color: #66859a !important;
}
.primaryDarkBlue-lite-40::after {
  content: "#66859a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-50 {
  background-color: #809aab !important;
}
.primaryDarkBlue-lite-50::after {
  content: "#809aab";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-60 {
  background-color: #99aebc !important;
}
.primaryDarkBlue-lite-60::after {
  content: "#99aebc";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-70 {
  background-color: #b3c2cd !important;
}
.primaryDarkBlue-lite-70::after {
  content: "#b3c2cd";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-80 {
  background-color: #ccd6dd !important;
}
.primaryDarkBlue-lite-80::after {
  content: "#ccd6dd";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-90 {
  background-color: #ecebee !important;
}
.primaryDarkBlue-lite-90::after {
  content: "#ecebee";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-100 {
  background-color: #ffffff !important;
}
.primaryDarkBlue-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-0 {
  background-color: #d42941 !important;
}
.alumni-0::after {
  content: "#d42941";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-10 {
  background-color: #bf253b !important;
}
.alumni-10::after {
  content: "#bf253b";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-20 {
  background-color: #aa2134 !important;
}
.alumni-20::after {
  content: "#aa2134";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-30 {
  background-color: #941d2e !important;
}
.alumni-30::after {
  content: "#941d2e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-40 {
  background-color: #7f1927 !important;
}
.alumni-40::after {
  content: "#7f1927";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-50 {
  background-color: #6a1521 !important;
}
.alumni-50::after {
  content: "#6a1521";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-60 {
  background-color: #55101a !important;
}
.alumni-60::after {
  content: "#55101a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-70 {
  background-color: #400c13 !important;
}
.alumni-70::after {
  content: "#400c13";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-80 {
  background-color: #2a080d !important;
}
.alumni-80::after {
  content: "#2a080d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-90 {
  background-color: #150406 !important;
}
.alumni-90::after {
  content: "#150406";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-100 {
  background-color: #000000 !important;
}
.alumni-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-0 {
  background-color: #d42941 !important;
}
.alumni-lite-0::after {
  content: "#d42941";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-10 {
  background-color: #d83e54 !important;
}
.alumni-lite-10::after {
  content: "#d83e54";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-20 {
  background-color: #dd5467 !important;
}
.alumni-lite-20::after {
  content: "#dd5467";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-30 {
  background-color: #e1697a !important;
}
.alumni-lite-30::after {
  content: "#e1697a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-40 {
  background-color: #e57f8d !important;
}
.alumni-lite-40::after {
  content: "#e57f8d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-50 {
  background-color: #ea94a0 !important;
}
.alumni-lite-50::after {
  content: "#ea94a0";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-60 {
  background-color: #eea9b3 !important;
}
.alumni-lite-60::after {
  content: "#eea9b3";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-70 {
  background-color: #f2bfc6 !important;
}
.alumni-lite-70::after {
  content: "#f2bfc6";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-80 {
  background-color: #f6d4d9 !important;
}
.alumni-lite-80::after {
  content: "#f6d4d9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-90 {
  background-color: #fbeaec !important;
}
.alumni-lite-90::after {
  content: "#fbeaec";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-100 {
  background-color: #ffffff !important;
}
.alumni-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-0 {
  background-color: #f05a2a !important;
}
.counsellors-0::after {
  content: "#f05a2a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-10 {
  background-color: #d85126 !important;
}
.counsellors-10::after {
  content: "#d85126";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-20 {
  background-color: #c04822 !important;
}
.counsellors-20::after {
  content: "#c04822";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-30 {
  background-color: #a83f1d !important;
}
.counsellors-30::after {
  content: "#a83f1d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-40 {
  background-color: #903619 !important;
}
.counsellors-40::after {
  content: "#903619";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-50 {
  background-color: #782d15 !important;
}
.counsellors-50::after {
  content: "#782d15";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-60 {
  background-color: #602411 !important;
}
.counsellors-60::after {
  content: "#602411";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-70 {
  background-color: #481b0d !important;
}
.counsellors-70::after {
  content: "#481b0d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-80 {
  background-color: #301208 !important;
}
.counsellors-80::after {
  content: "#301208";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-90 {
  background-color: #180904 !important;
}
.counsellors-90::after {
  content: "#180904";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-100 {
  background-color: #000000 !important;
}
.counsellors-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-0 {
  background-color: #f05a2a !important;
}
.counsellors-lite-0::after {
  content: "#f05a2a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-10 {
  background-color: #f26b3f !important;
}
.counsellors-lite-10::after {
  content: "#f26b3f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-20 {
  background-color: #f37b55 !important;
}
.counsellors-lite-20::after {
  content: "#f37b55";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-30 {
  background-color: #f58c6a !important;
}
.counsellors-lite-30::after {
  content: "#f58c6a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-40 {
  background-color: #f69c7f !important;
}
.counsellors-lite-40::after {
  content: "#f69c7f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-50 {
  background-color: #f8ad95 !important;
}
.counsellors-lite-50::after {
  content: "#f8ad95";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-60 {
  background-color: #f9bdaa !important;
}
.counsellors-lite-60::after {
  content: "#f9bdaa";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-70 {
  background-color: #fbcebf !important;
}
.counsellors-lite-70::after {
  content: "#fbcebf";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-80 {
  background-color: #fcded4 !important;
}
.counsellors-lite-80::after {
  content: "#fcded4";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-90 {
  background-color: #feefea !important;
}
.counsellors-lite-90::after {
  content: "#feefea";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-100 {
  background-color: #ffffff !important;
}
.counsellors-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-0 {
  background-color: #f8b018 !important;
}
.students-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-10 {
  background-color: #df9e16 !important;
}
.students-10::after {
  content: "#df9e16";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-20 {
  background-color: #c68d13 !important;
}
.students-20::after {
  content: "#c68d13";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-30 {
  background-color: #ae7b11 !important;
}
.students-30::after {
  content: "#ae7b11";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-40 {
  background-color: #956a0e !important;
}
.students-40::after {
  content: "#956a0e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-50 {
  background-color: #7c580c !important;
}
.students-50::after {
  content: "#7c580c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-60 {
  background-color: #63460a !important;
}
.students-60::after {
  content: "#63460a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-70 {
  background-color: #4a3507 !important;
}
.students-70::after {
  content: "#4a3507";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-80 {
  background-color: #322305 !important;
}
.students-80::after {
  content: "#322305";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-90 {
  background-color: #191202 !important;
}
.students-90::after {
  content: "#191202";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-100 {
  background-color: #000000 !important;
}
.students-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-0 {
  background-color: #f8b018 !important;
}
.students-lite-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-10 {
  background-color: #f9b82f !important;
}
.students-lite-10::after {
  content: "#f9b82f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-20 {
  background-color: #f9c046 !important;
}
.students-lite-20::after {
  content: "#f9c046";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-30 {
  background-color: #fac85d !important;
}
.students-lite-30::after {
  content: "#fac85d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-40 {
  background-color: #fbd074 !important;
}
.students-lite-40::after {
  content: "#fbd074";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-50 {
  background-color: #fcd88c !important;
}
.students-lite-50::after {
  content: "#fcd88c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-60 {
  background-color: #fcdfa3 !important;
}
.students-lite-60::after {
  content: "#fcdfa3";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-70 {
  background-color: #fde7ba !important;
}
.students-lite-70::after {
  content: "#fde7ba";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-80 {
  background-color: #feefd1 !important;
}
.students-lite-80::after {
  content: "#feefd1";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-90 {
  background-color: #fef7e8 !important;
}
.students-lite-90::after {
  content: "#fef7e8";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-100 {
  background-color: #ffffff !important;
}
.students-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-0 {
  background-color: #f8b018 !important;
}
.parents-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-10 {
  background-color: #df9e16 !important;
}
.parents-10::after {
  content: "#df9e16";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-20 {
  background-color: #c68d13 !important;
}
.parents-20::after {
  content: "#c68d13";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-30 {
  background-color: #ae7b11 !important;
}
.parents-30::after {
  content: "#ae7b11";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-40 {
  background-color: #956a0e !important;
}
.parents-40::after {
  content: "#956a0e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-50 {
  background-color: #7c580c !important;
}
.parents-50::after {
  content: "#7c580c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-60 {
  background-color: #63460a !important;
}
.parents-60::after {
  content: "#63460a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-70 {
  background-color: #4a3507 !important;
}
.parents-70::after {
  content: "#4a3507";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-80 {
  background-color: #322305 !important;
}
.parents-80::after {
  content: "#322305";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-90 {
  background-color: #191202 !important;
}
.parents-90::after {
  content: "#191202";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-100 {
  background-color: #000000 !important;
}
.parents-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-0 {
  background-color: #f8b018 !important;
}
.parents-lite-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-10 {
  background-color: #f9b82f !important;
}
.parents-lite-10::after {
  content: "#f9b82f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-20 {
  background-color: #f9c046 !important;
}
.parents-lite-20::after {
  content: "#f9c046";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-30 {
  background-color: #fac85d !important;
}
.parents-lite-30::after {
  content: "#fac85d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-40 {
  background-color: #fbd074 !important;
}
.parents-lite-40::after {
  content: "#fbd074";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-50 {
  background-color: #fcd88c !important;
}
.parents-lite-50::after {
  content: "#fcd88c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-60 {
  background-color: #fcdfa3 !important;
}
.parents-lite-60::after {
  content: "#fcdfa3";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-70 {
  background-color: #fde7ba !important;
}
.parents-lite-70::after {
  content: "#fde7ba";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-80 {
  background-color: #feefd1 !important;
}
.parents-lite-80::after {
  content: "#feefd1";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-90 {
  background-color: #fef7e8 !important;
}
.parents-lite-90::after {
  content: "#fef7e8";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-100 {
  background-color: #ffffff !important;
}
.parents-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

/*Primary Colors*/
/*Accent Colors*/
.university-0 {
  background-color: #51499C !important;
}
.university-0::after {
  content: "#51499C";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-10 {
  background-color: #49428c !important;
}
.university-10::after {
  content: "#49428c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-20 {
  background-color: #413a7d !important;
}
.university-20::after {
  content: "#413a7d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-30 {
  background-color: #39336d !important;
}
.university-30::after {
  content: "#39336d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-40 {
  background-color: #312c5e !important;
}
.university-40::after {
  content: "#312c5e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-50 {
  background-color: #29254e !important;
}
.university-50::after {
  content: "#29254e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-60 {
  background-color: #201d3e !important;
}
.university-60::after {
  content: "#201d3e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-70 {
  background-color: #18162f !important;
}
.university-70::after {
  content: "#18162f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-80 {
  background-color: #100f1f !important;
}
.university-80::after {
  content: "#100f1f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-90 {
  background-color: #080710 !important;
}
.university-90::after {
  content: "#080710";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-100 {
  background-color: #000000 !important;
}
.university-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-0 {
  background-color: #51499c !important;
}
.university-lite-0::after {
  content: "#51499c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-10 {
  background-color: #625ba6 !important;
}
.university-lite-10::after {
  content: "#625ba6";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-20 {
  background-color: #746db0 !important;
}
.university-lite-20::after {
  content: "#746db0";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-30 {
  background-color: #8580ba !important;
}
.university-lite-30::after {
  content: "#8580ba";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-40 {
  background-color: #9792c4 !important;
}
.university-lite-40::after {
  content: "#9792c4";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-50 {
  background-color: #a8a4ce !important;
}
.university-lite-50::after {
  content: "#a8a4ce";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-60 {
  background-color: #b9b6d7 !important;
}
.university-lite-60::after {
  content: "#b9b6d7";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-70 {
  background-color: #cbc8e1 !important;
}
.university-lite-70::after {
  content: "#cbc8e1";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-80 {
  background-color: #dcdbeb !important;
}
.university-lite-80::after {
  content: "#dcdbeb";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-90 {
  background-color: #eeedf5 !important;
}
.university-lite-90::after {
  content: "#eeedf5";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.university-lite-100 {
  background-color: #ffffff !important;
}
.university-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-0 {
  background-color: #5cae48 !important;
}
.primaryGreen-0::after {
  content: "#5cae48";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-10 {
  background-color: #539d41 !important;
}
.primaryGreen-10::after {
  content: "#539d41";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-20 {
  background-color: #4a8b3a !important;
}
.primaryGreen-20::after {
  content: "#4a8b3a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-30 {
  background-color: #407a32 !important;
}
.primaryGreen-30::after {
  content: "#407a32";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-40 {
  background-color: #37682b !important;
}
.primaryGreen-40::after {
  content: "#37682b";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-50 {
  background-color: #2e5724 !important;
}
.primaryGreen-50::after {
  content: "#2e5724";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-60 {
  background-color: #25461d !important;
}
.primaryGreen-60::after {
  content: "#25461d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-70 {
  background-color: #1c3416 !important;
}
.primaryGreen-70::after {
  content: "#1c3416";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-80 {
  background-color: #12230e !important;
}
.primaryGreen-80::after {
  content: "#12230e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-90 {
  background-color: #091107 !important;
}
.primaryGreen-90::after {
  content: "#091107";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-100 {
  background-color: #000000 !important;
}
.primaryGreen-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-0 {
  background-color: #5cae48 !important;
}
.primaryGreen-lite-0::after {
  content: "#5cae48";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-10 {
  background-color: #6cb65a !important;
}
.primaryGreen-lite-10::after {
  content: "#6cb65a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-20 {
  background-color: #7dbe6d !important;
}
.primaryGreen-lite-20::after {
  content: "#7dbe6d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-30 {
  background-color: #8dc67f !important;
}
.primaryGreen-lite-30::after {
  content: "#8dc67f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-40 {
  background-color: #9dce91 !important;
}
.primaryGreen-lite-40::after {
  content: "#9dce91";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-50 {
  background-color: #aed7a4 !important;
}
.primaryGreen-lite-50::after {
  content: "#aed7a4";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-60 {
  background-color: #bedfb6 !important;
}
.primaryGreen-lite-60::after {
  content: "#bedfb6";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-70 {
  background-color: #cee7c8 !important;
}
.primaryGreen-lite-70::after {
  content: "#cee7c8";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-80 {
  background-color: #deefda !important;
}
.primaryGreen-lite-80::after {
  content: "#deefda";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-90 {
  background-color: #eff7ed !important;
}
.primaryGreen-lite-90::after {
  content: "#eff7ed";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryGreen-lite-100 {
  background-color: #ffffff !important;
}
.primaryGreen-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-0 {
  background-color: #26a7df !important;
}
.schools-0::after {
  content: "#26a7df";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-10 {
  background-color: #2296c9 !important;
}
.schools-10::after {
  content: "#2296c9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-20 {
  background-color: #1e86b2 !important;
}
.schools-20::after {
  content: "#1e86b2";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-30 {
  background-color: #1b759c !important;
}
.schools-30::after {
  content: "#1b759c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-40 {
  background-color: #176486 !important;
}
.schools-40::after {
  content: "#176486";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-50 {
  background-color: #135470 !important;
}
.schools-50::after {
  content: "#135470";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-60 {
  background-color: #0f4359 !important;
}
.schools-60::after {
  content: "#0f4359";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-70 {
  background-color: #0b3243 !important;
}
.schools-70::after {
  content: "#0b3243";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-80 {
  background-color: #08212d !important;
}
.schools-80::after {
  content: "#08212d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-90 {
  background-color: #041116 !important;
}
.schools-90::after {
  content: "#041116";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-100 {
  background-color: #000000 !important;
}
.schools-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-0 {
  background-color: #26a7df !important;
}
.schools-lite-0::after {
  content: "#26a7df";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-10 {
  background-color: #3cb0e2 !important;
}
.schools-lite-10::after {
  content: "#3cb0e2";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-20 {
  background-color: #51b9e5 !important;
}
.schools-lite-20::after {
  content: "#51b9e5";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-30 {
  background-color: #67c1e9 !important;
}
.schools-lite-30::after {
  content: "#67c1e9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-40 {
  background-color: #7dcaec !important;
}
.schools-lite-40::after {
  content: "#7dcaec";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-50 {
  background-color: #93d3ef !important;
}
.schools-lite-50::after {
  content: "#93d3ef";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-60 {
  background-color: #a8dcf2 !important;
}
.schools-lite-60::after {
  content: "#a8dcf2";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-70 {
  background-color: #bee5f5 !important;
}
.schools-lite-70::after {
  content: "#bee5f5";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-80 {
  background-color: #d4edf9 !important;
}
.schools-lite-80::after {
  content: "#d4edf9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-90 {
  background-color: #e9f6fc !important;
}
.schools-lite-90::after {
  content: "#e9f6fc";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.schools-lite-100 {
  background-color: #ffffff !important;
}
.schools-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-0 {
  background-color: #003457 !important;
}
.primaryDarkBlue-0::after {
  content: "#003457";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-10 {
  background-color: #002f4e !important;
}
.primaryDarkBlue-10::after {
  content: "#002f4e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-20 {
  background-color: #002a46 !important;
}
.primaryDarkBlue-20::after {
  content: "#002a46";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-30 {
  background-color: #00243d !important;
}
.primaryDarkBlue-30::after {
  content: "#00243d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-40 {
  background-color: #001f34 !important;
}
.primaryDarkBlue-40::after {
  content: "#001f34";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-50 {
  background-color: #001a2c !important;
}
.primaryDarkBlue-50::after {
  content: "#001a2c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-60 {
  background-color: #001523 !important;
}
.primaryDarkBlue-60::after {
  content: "#001523";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-70 {
  background-color: #00101a !important;
}
.primaryDarkBlue-70::after {
  content: "#00101a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-80 {
  background-color: #000a11 !important;
}
.primaryDarkBlue-80::after {
  content: "#000a11";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-90 {
  background-color: #000509 !important;
}
.primaryDarkBlue-90::after {
  content: "#000509";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-100 {
  background-color: #000000 !important;
}
.primaryDarkBlue-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-0 {
  background-color: #003457 !important;
}
.primaryDarkBlue-lite-0::after {
  content: "#003457";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-10 {
  background-color: #1a4868 !important;
}
.primaryDarkBlue-lite-10::after {
  content: "#1a4868";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-20 {
  background-color: #335d79 !important;
}
.primaryDarkBlue-lite-20::after {
  content: "#335d79";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-30 {
  background-color: #4d7189 !important;
}
.primaryDarkBlue-lite-30::after {
  content: "#4d7189";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-40 {
  background-color: #66859a !important;
}
.primaryDarkBlue-lite-40::after {
  content: "#66859a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-50 {
  background-color: #809aab !important;
}
.primaryDarkBlue-lite-50::after {
  content: "#809aab";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-60 {
  background-color: #99aebc !important;
}
.primaryDarkBlue-lite-60::after {
  content: "#99aebc";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-70 {
  background-color: #b3c2cd !important;
}
.primaryDarkBlue-lite-70::after {
  content: "#b3c2cd";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-80 {
  background-color: #ccd6dd !important;
}
.primaryDarkBlue-lite-80::after {
  content: "#ccd6dd";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-90 {
  background-color: #ecebee !important;
}
.primaryDarkBlue-lite-90::after {
  content: "#ecebee";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.primaryDarkBlue-lite-100 {
  background-color: #ffffff !important;
}
.primaryDarkBlue-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-0 {
  background-color: #d42941 !important;
}
.alumni-0::after {
  content: "#d42941";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-10 {
  background-color: #bf253b !important;
}
.alumni-10::after {
  content: "#bf253b";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-20 {
  background-color: #aa2134 !important;
}
.alumni-20::after {
  content: "#aa2134";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-30 {
  background-color: #941d2e !important;
}
.alumni-30::after {
  content: "#941d2e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-40 {
  background-color: #7f1927 !important;
}
.alumni-40::after {
  content: "#7f1927";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-50 {
  background-color: #6a1521 !important;
}
.alumni-50::after {
  content: "#6a1521";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-60 {
  background-color: #55101a !important;
}
.alumni-60::after {
  content: "#55101a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-70 {
  background-color: #400c13 !important;
}
.alumni-70::after {
  content: "#400c13";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-80 {
  background-color: #2a080d !important;
}
.alumni-80::after {
  content: "#2a080d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-90 {
  background-color: #150406 !important;
}
.alumni-90::after {
  content: "#150406";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-100 {
  background-color: #000000 !important;
}
.alumni-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-0 {
  background-color: #d42941 !important;
}
.alumni-lite-0::after {
  content: "#d42941";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-10 {
  background-color: #d83e54 !important;
}
.alumni-lite-10::after {
  content: "#d83e54";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-20 {
  background-color: #dd5467 !important;
}
.alumni-lite-20::after {
  content: "#dd5467";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-30 {
  background-color: #e1697a !important;
}
.alumni-lite-30::after {
  content: "#e1697a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-40 {
  background-color: #e57f8d !important;
}
.alumni-lite-40::after {
  content: "#e57f8d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-50 {
  background-color: #ea94a0 !important;
}
.alumni-lite-50::after {
  content: "#ea94a0";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-60 {
  background-color: #eea9b3 !important;
}
.alumni-lite-60::after {
  content: "#eea9b3";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-70 {
  background-color: #f2bfc6 !important;
}
.alumni-lite-70::after {
  content: "#f2bfc6";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-80 {
  background-color: #f6d4d9 !important;
}
.alumni-lite-80::after {
  content: "#f6d4d9";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-90 {
  background-color: #fbeaec !important;
}
.alumni-lite-90::after {
  content: "#fbeaec";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.alumni-lite-100 {
  background-color: #ffffff !important;
}
.alumni-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-0 {
  background-color: #f05a2a !important;
}
.counsellors-0::after {
  content: "#f05a2a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-10 {
  background-color: #d85126 !important;
}
.counsellors-10::after {
  content: "#d85126";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-20 {
  background-color: #c04822 !important;
}
.counsellors-20::after {
  content: "#c04822";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-30 {
  background-color: #a83f1d !important;
}
.counsellors-30::after {
  content: "#a83f1d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-40 {
  background-color: #903619 !important;
}
.counsellors-40::after {
  content: "#903619";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-50 {
  background-color: #782d15 !important;
}
.counsellors-50::after {
  content: "#782d15";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-60 {
  background-color: #602411 !important;
}
.counsellors-60::after {
  content: "#602411";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-70 {
  background-color: #481b0d !important;
}
.counsellors-70::after {
  content: "#481b0d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-80 {
  background-color: #301208 !important;
}
.counsellors-80::after {
  content: "#301208";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-90 {
  background-color: #180904 !important;
}
.counsellors-90::after {
  content: "#180904";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-100 {
  background-color: #000000 !important;
}
.counsellors-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-0 {
  background-color: #f05a2a !important;
}
.counsellors-lite-0::after {
  content: "#f05a2a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-10 {
  background-color: #f26b3f !important;
}
.counsellors-lite-10::after {
  content: "#f26b3f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-20 {
  background-color: #f37b55 !important;
}
.counsellors-lite-20::after {
  content: "#f37b55";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-30 {
  background-color: #f58c6a !important;
}
.counsellors-lite-30::after {
  content: "#f58c6a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-40 {
  background-color: #f69c7f !important;
}
.counsellors-lite-40::after {
  content: "#f69c7f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-50 {
  background-color: #f8ad95 !important;
}
.counsellors-lite-50::after {
  content: "#f8ad95";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-60 {
  background-color: #f9bdaa !important;
}
.counsellors-lite-60::after {
  content: "#f9bdaa";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-70 {
  background-color: #fbcebf !important;
}
.counsellors-lite-70::after {
  content: "#fbcebf";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-80 {
  background-color: #fcded4 !important;
}
.counsellors-lite-80::after {
  content: "#fcded4";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-90 {
  background-color: #feefea !important;
}
.counsellors-lite-90::after {
  content: "#feefea";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.counsellors-lite-100 {
  background-color: #ffffff !important;
}
.counsellors-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-0 {
  background-color: #f8b018 !important;
}
.students-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-10 {
  background-color: #df9e16 !important;
}
.students-10::after {
  content: "#df9e16";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-20 {
  background-color: #c68d13 !important;
}
.students-20::after {
  content: "#c68d13";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-30 {
  background-color: #ae7b11 !important;
}
.students-30::after {
  content: "#ae7b11";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-40 {
  background-color: #956a0e !important;
}
.students-40::after {
  content: "#956a0e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-50 {
  background-color: #7c580c !important;
}
.students-50::after {
  content: "#7c580c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-60 {
  background-color: #63460a !important;
}
.students-60::after {
  content: "#63460a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-70 {
  background-color: #4a3507 !important;
}
.students-70::after {
  content: "#4a3507";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-80 {
  background-color: #322305 !important;
}
.students-80::after {
  content: "#322305";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-90 {
  background-color: #191202 !important;
}
.students-90::after {
  content: "#191202";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-100 {
  background-color: #000000 !important;
}
.students-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-0 {
  background-color: #f8b018 !important;
}
.students-lite-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-10 {
  background-color: #f9b82f !important;
}
.students-lite-10::after {
  content: "#f9b82f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-20 {
  background-color: #f9c046 !important;
}
.students-lite-20::after {
  content: "#f9c046";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-30 {
  background-color: #fac85d !important;
}
.students-lite-30::after {
  content: "#fac85d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-40 {
  background-color: #fbd074 !important;
}
.students-lite-40::after {
  content: "#fbd074";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-50 {
  background-color: #fcd88c !important;
}
.students-lite-50::after {
  content: "#fcd88c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-60 {
  background-color: #fcdfa3 !important;
}
.students-lite-60::after {
  content: "#fcdfa3";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-70 {
  background-color: #fde7ba !important;
}
.students-lite-70::after {
  content: "#fde7ba";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-80 {
  background-color: #feefd1 !important;
}
.students-lite-80::after {
  content: "#feefd1";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-90 {
  background-color: #fef7e8 !important;
}
.students-lite-90::after {
  content: "#fef7e8";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.students-lite-100 {
  background-color: #ffffff !important;
}
.students-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-0 {
  background-color: #f8b018 !important;
}
.parents-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-10 {
  background-color: #df9e16 !important;
}
.parents-10::after {
  content: "#df9e16";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-20 {
  background-color: #c68d13 !important;
}
.parents-20::after {
  content: "#c68d13";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-30 {
  background-color: #ae7b11 !important;
}
.parents-30::after {
  content: "#ae7b11";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-40 {
  background-color: #956a0e !important;
}
.parents-40::after {
  content: "#956a0e";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-50 {
  background-color: #7c580c !important;
}
.parents-50::after {
  content: "#7c580c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-60 {
  background-color: #63460a !important;
}
.parents-60::after {
  content: "#63460a";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-70 {
  background-color: #4a3507 !important;
}
.parents-70::after {
  content: "#4a3507";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-80 {
  background-color: #322305 !important;
}
.parents-80::after {
  content: "#322305";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-90 {
  background-color: #191202 !important;
}
.parents-90::after {
  content: "#191202";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-100 {
  background-color: #000000 !important;
}
.parents-100::after {
  content: "#000000";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-0 {
  background-color: #f8b018 !important;
}
.parents-lite-0::after {
  content: "#f8b018";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-10 {
  background-color: #f9b82f !important;
}
.parents-lite-10::after {
  content: "#f9b82f";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-20 {
  background-color: #f9c046 !important;
}
.parents-lite-20::after {
  content: "#f9c046";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-30 {
  background-color: #fac85d !important;
}
.parents-lite-30::after {
  content: "#fac85d";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-40 {
  background-color: #fbd074 !important;
}
.parents-lite-40::after {
  content: "#fbd074";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-50 {
  background-color: #fcd88c !important;
}
.parents-lite-50::after {
  content: "#fcd88c";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-60 {
  background-color: #fcdfa3 !important;
}
.parents-lite-60::after {
  content: "#fcdfa3";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-70 {
  background-color: #fde7ba !important;
}
.parents-lite-70::after {
  content: "#fde7ba";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-80 {
  background-color: #feefd1 !important;
}
.parents-lite-80::after {
  content: "#feefd1";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-90 {
  background-color: #fef7e8 !important;
}
.parents-lite-90::after {
  content: "#fef7e8";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

.parents-lite-100 {
  background-color: #ffffff !important;
}
.parents-lite-100::after {
  content: "#ffffff";
  position: relative;
  top: 30px;
  display: flex;
  justify-content: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
  line-height: 22px;
}
.font-21 {
  font-size: 21px;
}
.font-22 {
  font-size: 22px;
}
.font-23 {
  font-size: 23px;
}
.font-24 {
  font-size: 24px;
}
.font-25 {
  font-size: 25px;
}
.font-26 {
  font-size: 26px;
}
.font-27 {
  font-size: 27px;
}
.font-28 {
  font-size: 28px;
}
.font-29 {
  font-size: 29px;
}
.font-30 {
  font-size: 30px;
}
.font-31 {
  font-size: 31px;
}
.font-32 {
  font-size: 32px;
}
.font-100 {
  font-weight: 100;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.font-900 {
  font-weight: 900;
}

.text-dark-gray {
  color: #3A3A3A;
}

.flex-1 {
  flex: 1;
}

.w-140 {
  width: 140px;
}
.w-270 {
  max-width: 270px;
  width: 100%;
}
.w-312 {
  max-width: 312px;
  width: 100%;
}
.w-800 {
  max-width: 800px;
  width: 100%;
}
.w-860 {
  max-width: 860px;
  width: 100%;
}
.w-1030 {
  max-width: 1030px;
  width: 100%;
}

.height-46 {
  height: 46px;
}

.min-height-250 {
  min-height: 250px;
}

.bg-grd-blue {
  background: rgb(23, 76, 117);
  background: radial-gradient(circle, rgb(23, 76, 117) 0%, rgb(9, 50, 83) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#174c75",endColorstr="#093253",GradientType=1);
}

.btn-green {
  background: #5CAE48;
  border-radius: 25px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.btn-green:hover {
  background: #f8b018;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
}
.wrapper .dark-section {
  flex: 1;
  background-color: rgb(11, 52, 84);
  background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/collage-building.svg);
  background-repeat: no-repeat;
  background-position: right top 30%;
  background-blend-mode: multiply;
}
@media (max-width: 575px) {
  .wrapper .dark-section {
    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 93%, 50% 100%, 0 93%, 0 0);
            clip-path: polygon(50% 0%, 100% 0, 100% 93%, 50% 100%, 0 93%, 0 0);
  }
}
.wrapper .dark-section.student-img {
  background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/gradute.svg);
  background-position: bottom center;
  background-size: 800px;
}
@media (max-width: 575px) {
  .wrapper .dark-section.student-img {
    background-size: 450px;
  }
}
.wrapper .dark-section.bg-grd-blue {
  background: rgb(23, 76, 117);
  background: radial-gradient(circle, rgb(23, 76, 117) 0%, rgb(9, 50, 83) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#174c75",endColorstr="#093253",GradientType=1);
}
.wrapper .dark-section .logo {
  background: #fff;
  display: flex;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
  align-items: end;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 30px;
  max-width: 300px;
  max-height: 230px;
  min-width: 300px;
}
.wrapper .dark-section .logo img {
  height: 100%;
  width: auto;
  max-width: 100%;
}
.wrapper .dark-section .home-page-title {
  font-size: 40px;
  text-align: center;
}
@media (max-width: 1024px) {
  .wrapper .dark-section .home-page-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .wrapper .dark-section .home-page-title {
    font-size: 23px;
  }
}
.wrapper .dark-section .sep {
  width: 285px;
  height: 2px;
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 30px 0;
}
.wrapper .dark-section .sep.blue {
  height: 2px;
  background: #2B6B9F;
  margin: 15px 0 45px;
}
.wrapper .dark-section .school-name {
  font-size: 45px;
  line-height: 50px;
  text-align: center;
}
@media (max-width: 1024px) {
  .wrapper .dark-section .school-name {
    font-size: 50px;
    line-height: 60px;
  }
}
.wrapper .dark-section .sub-school-tag {
  font-size: 36px;
  text-align: center;
  line-height: 51px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .wrapper .dark-section .sub-school-tag {
    font-size: 30px;
    line-height: 34px;
  }
}
.wrapper .dark-section .header-icon {
  margin-bottom: 22px;
  display: flex;
  align-items: end;
  position: relative;
}
.wrapper .dark-section .header-icon::before {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #022642;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  top: 0;
  right: -10px;
}
.wrapper .dark-section .list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 30px;
}
@media (max-width: 575px) {
  .wrapper .dark-section .list {
    font-size: 20px;
    line-height: 30px;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 50px;
  }
}
.wrapper .dark-section .list li {
  position: relative;
  padding-left: 40px;
}
.wrapper .dark-section .list li::before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/green-check.svg) no-repeat left top/100%;
  width: 24px;
  height: 24px;
}
@media (max-width: 575px) {
  .wrapper .dark-section .list li::before {
    width: 20px;
    height: 20px;
    top: 5px;
  }
}
.wrapper .dark-section .list li:not(:last-child) {
  margin-bottom: 30px;
}
.wrapper .dark-section .id-title {
  font-size: 30px;
  line-height: 45px;
  font-weight: 600;
}
.wrapper .dark-section .form-title {
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  border: none;
}
@media (max-width: 575px) {
  .wrapper .dark-section .form-title {
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    font-weight: 500;
  }
}
.wrapper .dark-section .white-label {
  background: white;
  width: 100%;
  height: 60px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  max-width: 200px;
  min-width: 200px;
  padding: 0 25px;
  color: black;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}
.wrapper .dark-section .white-label i {
  width: 13px;
  height: 13px;
  border: 1px solid #666666;
  border-radius: 50%;
  margin-right: 10px;
}
.wrapper .dark-section .form-check-input:checked + .white-label i {
  border-color: #5CAE48;
  background-color: #5CAE48;
}
.wrapper .dark-section .btn-submit {
  width: 140px;
  background: #5CAE48;
  border-radius: 25px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.wrapper .dark-section .btn-submit:hover {
  background: #f8b018;
}
.wrapper .footer-text-1 {
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 1024px) {
  .wrapper .footer-text-1 {
    font-size: 24px;
    line-height: 32px;
  }
}
.wrapper .footer-text-2 {
  font-size: 30px;
  text-align: center;
}
@media (max-width: 1024px) {
  .wrapper .footer-text-2 {
    font-size: 24px;
  }
}
.wrapper .principal-wrap {
  width: 281px;
  position: relative;
  margin: 0 auto;
}
.wrapper .principal-wrap .arrow-img {
  position: absolute;
  right: calc(100% + 10px);
  bottom: 10px;
}
.wrapper .principal-wrap .btn-green {
  width: 100%;
  background: #5CAE48;
  border-radius: 25px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.wrapper .principal-wrap .btn-green:hover {
  background: #f8b018;
}
.wrapper .navigation_footer_wrap {
  position: sticky;
  bottom: 30px;
  height: 0;
}
@media (max-width: 575px) {
  .wrapper .navigation_footer_wrap {
    height: 50px;
  }
}
.wrapper .navigation_footer_wrap .navigation_footer {
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 80px);
  height: 55px;
  margin: auto;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  background: #fff;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  align-items: center;
  padding: 0 5px;
  box-shadow: 0 0px 10px 1px rgba(118, 118, 118, 0.1803921569);
}
.wrapper .navigation_footer_wrap .navigation_footer a {
  width: auto;
  height: 45px;
  display: flex;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  align-items: center;
  padding: 0 20px;
  background-color: #5CAE48;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.wrapper .navigation_footer_wrap .navigation_footer a.btn-back i {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 12px;
  width: 10px;
  height: 20px;
  margin-right: 7px;
}
.wrapper .navigation_footer_wrap .navigation_footer a.btn-next i {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 12px;
  width: 10px;
  height: 20px;
  margin-left: 7px;
}
.wrapper .navigation_footer_wrap .navigation_footer a:hover {
  background-color: #35911f;
  text-decoration: none;
}
.wrapper .navigation_footer_wrap .navigation_footer a span {
  text-decoration: none;
}

.left-graph {
  position: absolute;
  left: 0;
  top: -40%;
  width: 50%;
}
@media (max-width: 1500px) {
  .left-graph {
    top: -30%;
  }
}
@media (max-width: 1250px) {
  .left-graph {
    top: -20%;
  }
}
@media (max-width: 1100px) {
  .left-graph {
    top: -10%;
  }
}
@media (max-width: 575px) {
  .left-graph {
    top: 0%;
  }
}
.left-graph img {
  width: 100%;
}

.right-graph {
  position: absolute;
  right: 0;
  top: -71%;
  width: 50%;
}
@media (max-width: 1500px) {
  .right-graph {
    top: -50%;
  }
}
@media (max-width: 1250px) {
  .right-graph {
    top: -40%;
  }
}
@media (max-width: 1100px) {
  .right-graph {
    top: -30%;
  }
}
@media (max-width: 575px) {
  .right-graph {
    top: -9%;
    width: 70%;
  }
}
.right-graph img {
  width: 100%;
}

.thanksTxt {
  font-size: 100px;
  font-weight: 700;
  margin: 30px 0;
  font-style: italic;
}
@media (max-width: 1100px) {
  .thanksTxt {
    font-size: 60px;
    margin: 20px 0;
  }
}
.thanksTxt-sub {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 575px) {
  .thanksTxt-sub {
    font-size: 24px;
  }
}
.thanksTxt-sub-2 {
  font-size: 46px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 575px) {
  .thanksTxt-sub-2 {
    font-size: 30px;
  }
}

@media (max-width: 1100px) {
  .thumb img {
    max-width: 130px;
  }
}
.form-label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: -0.25rem; /* Prevent the error message from adding extra space */
  margin-bottom: 0.5rem;
  position: absolute; /* Position the error message absolutely */
}

.form-group {
  position: relative; /* Necessary to position the error message within the form group */
  margin-bottom: 1.5rem; /* Add space between form groups */
}

input.error {
  border-color: red;
}/*# sourceMappingURL=style.css.map */
/*Primary Colors*/
$primary-color:#0F0D2C;
$secondary-color:#FF6580;

$base-color:#0A0A0A;

//dynamic height for form filed
$lgform:50px;
$mdform:40px;
$smform:30px;

/*Accent Colors*/

$black:#000000;
$white:#ffffff;

$dark-gray: #3A3A3A;

$beige-line: #F8B018;
$purple-line: #51499C;
$orange-line: #F05A2A;
$green-line: #5CAE48;
$pink-line: #ee4c61;


$university: #51499C;
$university-10: #49428c;
$university-20: #413a7d;
$university-30: #39336d;
$university-40: #312c5e;
$university-50: #29254e;
$university-60: #201d3e;
$university-70: #18162f;
$university-80: #100f1f;
$university-90: #080710;

$university-lite-10: #625ba6;
$university-lite-20: #746db0;
$university-lite-30: #8580ba;
$university-lite-40: #9792c4;
$university-lite-50: #a8a4ce;
$university-lite-60: #b9b6d7;
$university-lite-70: #cbc8e1;
$university-lite-80: #dcdbeb;
$university-lite-90: #eeedf5;

$primaryGreen: #5cae48;
$primaryGreen-10: #539d41;
$primaryGreen-20: #4a8b3a;
$primaryGreen-30: #407a32;
$primaryGreen-40: #37682b;
$primaryGreen-50: #2e5724;
$primaryGreen-60: #25461d;
$primaryGreen-70: #1c3416;
$primaryGreen-80: #12230e;
$primaryGreen-90: #091107;

$primaryGreen-lite-10: #6cb65a;
$primaryGreen-lite-20: #7dbe6d;
$primaryGreen-lite-30: #8dc67f;
$primaryGreen-lite-40: #9dce91;
$primaryGreen-lite-50: #aed7a4;
$primaryGreen-lite-60: #bedfb6;
$primaryGreen-lite-70: #cee7c8;
$primaryGreen-lite-80: #deefda;
$primaryGreen-lite-90: #eff7ed;

$schools: #26a7df;
$schools-10: #2296c9;
$schools-20: #1e86b2;
$schools-30: #1b759c;
$schools-40: #176486;
$schools-50: #135470;
$schools-60: #0f4359;
$schools-70: #0b3243;
$schools-80: #08212d;
$schools-90: #041116;

$schools-lite-10: #3cb0e2;
$schools-lite-20: #51b9e5;
$schools-lite-30: #67c1e9;
$schools-lite-40: #7dcaec;
$schools-lite-50: #93d3ef;
$schools-lite-60: #a8dcf2;
$schools-lite-70: #bee5f5;
$schools-lite-80: #d4edf9;
$schools-lite-90: #e9f6fc;

$primaryDarkBlue: #003457;
$primaryDarkBlue-10: #002f4e;
$primaryDarkBlue-20: #002a46;
$primaryDarkBlue-30: #00243d;
$primaryDarkBlue-40: #001f34;
$primaryDarkBlue-50: #001a2c;
$primaryDarkBlue-60: #001523;
$primaryDarkBlue-70: #00101a;
$primaryDarkBlue-80: #000a11;
$primaryDarkBlue-90: #000509;

$primaryDarkBlue-lite-10: #1a4868;
$primaryDarkBlue-lite-20: #335d79;
$primaryDarkBlue-lite-30: #4d7189;
$primaryDarkBlue-lite-40: #66859a;
$primaryDarkBlue-lite-50: #809aab;
$primaryDarkBlue-lite-60: #99aebc;
$primaryDarkBlue-lite-70: #b3c2cd;
$primaryDarkBlue-lite-80: #ccd6dd;
$primaryDarkBlue-lite-90: #ecebee;

$alumni: #d42941;
$alumni-10: #bf253b;
$alumni-20: #aa2134;
$alumni-30: #941d2e;
$alumni-40: #7f1927;
$alumni-50: #6a1521;
$alumni-60: #55101a;
$alumni-70: #400c13;
$alumni-80: #2a080d;
$alumni-90: #150406;
  
$alumni-lite-10: #d83e54;
$alumni-lite-20: #dd5467;
$alumni-lite-30: #e1697a;
$alumni-lite-40: #e57f8d;
$alumni-lite-50: #ea94a0;
$alumni-lite-60: #eea9b3;
$alumni-lite-70: #f2bfc6;
$alumni-lite-80: #f6d4d9;
$alumni-lite-90: #fbeaec;

$counsellors: #f05a2a;
$counsellors-10: #d85126;
$counsellors-20: #c04822;
$counsellors-30: #a83f1d;
$counsellors-40: #903619;
$counsellors-50: #782d15;
$counsellors-60: #602411;
$counsellors-70: #481b0d;
$counsellors-80: #301208;
$counsellors-90: #180904;

$counsellors-lite-10: #f26b3f;
$counsellors-lite-20: #f37b55;
$counsellors-lite-30: #f58c6a;
$counsellors-lite-40: #f69c7f;
$counsellors-lite-50: #f8ad95;
$counsellors-lite-60: #f9bdaa;
$counsellors-lite-70: #fbcebf;
$counsellors-lite-80: #fcded4;
$counsellors-lite-90: #feefea;

$students: #f8b018;
$students-10: #df9e16;
$students-20: #c68d13;
$students-30: #ae7b11;
$students-40: #956a0e;
$students-50: #7c580c;
$students-60: #63460a;
$students-70: #4a3507;
$students-80: #322305;
$students-90: #191202;

$students-lite-10: #f9b82f;
$students-lite-20: #f9c046;
$students-lite-30: #fac85d;
$students-lite-40: #fbd074;
$students-lite-50: #fcd88c;
$students-lite-60: #fcdfa3;
$students-lite-70: #fde7ba;
$students-lite-80: #feefd1;
$students-lite-90: #fef7e8;

$parents: #f8b018;
$parents-10: #df9e16;
$parents-20: #c68d13;
$parents-30: #ae7b11;
$parents-40: #956a0e;
$parents-50: #7c580c;
$parents-60: #63460a;
$parents-70: #4a3507;
$parents-80: #322305;
$parents-90: #191202;

$parents-lite-10: #f9b82f;
$parents-lite-20: #f9c046;
$parents-lite-30: #fac85d;
$parents-lite-40: #fbd074;
$parents-lite-50: #fcd88c;
$parents-lite-60: #fcdfa3;
$parents-lite-70: #fde7ba;
$parents-lite-80: #feefd1;
$parents-lite-90: #fef7e8;

$colors: (
  university: (
    0: #51499C,
    10: #49428c,
    20: #413a7d,
    30: #39336d,
    40: #312c5e,
    50: #29254e,
    60: #201d3e,
    70: #18162f,
    80: #100f1f,
    90: #080710,
    100: #000000
  ),
  university-lite: (
    0: #51499c,
    10: #625ba6,
    20: #746db0,
    30: #8580ba,
    40: #9792c4,
    50: #a8a4ce,
    60: #b9b6d7,
    70: #cbc8e1,
    80: #dcdbeb,
    90: #eeedf5,
    100: #ffffff
  ),
  primaryGreen: (
    0: #5cae48,
    10: #539d41,
    20: #4a8b3a,
    30: #407a32,
    40: #37682b,
    50: #2e5724,
    60: #25461d,
    70: #1c3416,
    80: #12230e,
    90: #091107,
    100: #000000
  ),
  primaryGreen-lite: (
    0: #5cae48,
    10: #6cb65a,
    20: #7dbe6d,
    30: #8dc67f,
    40: #9dce91,
    50: #aed7a4,
    60: #bedfb6,
    70: #cee7c8,
    80: #deefda,
    90: #eff7ed,
    100: #ffffff
  ),
  schools: (
    0: #26a7df,
    10: #2296c9,
    20: #1e86b2,
    30: #1b759c,
    40: #176486,
    50: #135470,
    60: #0f4359,
    70: #0b3243,
    80: #08212d,
    90: #041116,
    100: #000000
  ),
  schools-lite: (
    0: #26a7df,
    10: #3cb0e2,
    20: #51b9e5,
    30: #67c1e9,
    40: #7dcaec,
    50: #93d3ef,
    60: #a8dcf2,
    70: #bee5f5,
    80: #d4edf9,
    90: #e9f6fc,
    100: #ffffff
  ),
  primaryDarkBlue: (
    0: #003457,
    10: #002f4e,
    20: #002a46,
    30: #00243d,
    40: #001f34,
    50: #001a2c,
    60: #001523,
    70: #00101a,
    80: #000a11,
    90: #000509,
    100: #000000
  ),
  primaryDarkBlue-lite: (
    0: #003457,
    10: #1a4868,
    20: #335d79,
    30: #4d7189,
    40: #66859a,
    50: #809aab,
    60: #99aebc,
    70: #b3c2cd,
    80: #ccd6dd,
    90: #ecebee,
    100: #ffffff
  ),
  alumni: (
    0: #d42941,
    10: #bf253b,
    20: #aa2134,
    30: #941d2e,
    40: #7f1927,
    50: #6a1521,
    60: #55101a,
    70: #400c13,
    80: #2a080d,
    90: #150406,
    100: #000000
  ),
  alumni-lite: (
    0: #d42941,
    10: #d83e54,
    20: #dd5467,
    30: #e1697a,
    40: #e57f8d,
    50: #ea94a0,
    60: #eea9b3,
    70: #f2bfc6,
    80: #f6d4d9,
    90: #fbeaec,
    100: #ffffff
  ),
  counsellors: (
    0: #f05a2a,
    10: #d85126,
    20: #c04822,
    30: #a83f1d,
    40: #903619,
    50: #782d15,
    60: #602411,
    70: #481b0d,
    80: #301208,
    90: #180904,
    100: #000000
  ),
  counsellors-lite: (
    0: #f05a2a,
    10: #f26b3f,
    20: #f37b55,
    30: #f58c6a,
    40: #f69c7f,
    50: #f8ad95,
    60: #f9bdaa,
    70: #fbcebf,
    80: #fcded4,
    90: #feefea,
    100: #ffffff
  ),
  students: (
    0: #f8b018,
    10: #df9e16,
    20: #c68d13,
    30: #ae7b11,
    40: #956a0e,
    50: #7c580c,
    60: #63460a,
    70: #4a3507,
    80: #322305,
    90: #191202,
    100: #000000
  ),
  students-lite: (
    0: #f8b018,
    10: #f9b82f,
    20: #f9c046,
    30: #fac85d,
    40: #fbd074,
    50: #fcd88c,
    60: #fcdfa3,
    70: #fde7ba,
    80: #feefd1,
    90: #fef7e8,
    100: #ffffff
  ),
  parents: (
    0: #f8b018,
    10: #df9e16,
    20: #c68d13,
    30: #ae7b11,
    40: #956a0e,
    50: #7c580c,
    60: #63460a,
    70: #4a3507,
    80: #322305,
    90: #191202,
    100: #000000
  ),
  parents-lite: (
    0: #f8b018,
    10: #f9b82f,
    20: #f9c046,
    30: #fac85d,
    40: #fbd074,
    50: #fcd88c,
    60: #fcdfa3,
    70: #fde7ba,
    80: #feefd1,
    90: #fef7e8,
    100: #ffffff
  )
);

@each $color-key, $color-value in $colors {
  @each $tone-key, $tone-value in $color-value {
    @if $tone-key == base {
      .#{$color-key} {
        background-color: $tone-value !important;
      }
    } @else {
      .#{$color-key}-#{$tone-key} {
        background-color: $tone-value !important;
        &::after{
          content: '#{$tone-value}';
          position: relative;
          top: 30px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
/* #login-form #buttonPallet {
    background-color: transparent !important;
}

#buttonPallet button {
    background-color: #5cae48;
} */


.register_wrap.bg-darkblue {
	background: #0d3453;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
}
.login_wrapper.full_page_login {padding: 50px;background: #fff;border-radius: 5px;}

.login_wrapper.full_page_login + .fpw_wrapper {padding: 50px;background: #fff;border-radius: 5px;}

.view_pw a.btn {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    height: 20px;
    padding: 0;
    display: flex;
    margin: auto;
    align-items: center;
  }
  .view_pw > a.btn img {
    width: 20px;
    height: auto;
  }


  .view_pw {
	position: relative;
}

#InputPassword[type="password"] + a .fview_icn {
	display: none;
}

#InputPassword[type="text"] + a .fview_strike_icn {
	display: none;
}
.login_footer {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;justify-content: space-between;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;position: absolute;left: 0;bottom: 35px;width: 100%;}
.login_footer .powerby {width: auto;min-width: initial !important;}

.border-top-7b {
	border-top: 1px solid #124d7b !important;
}
@import 'variable.scss'; 

@mixin flex-1() {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@mixin flex-1() {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
@mixin flexNo($flexNo) {
    -webkit-box-flex: $flexNo;
    -moz-box-flex: $flexNo;
    -webkit-flex: $flexNo;
    -ms-flex: $flexNo;
    flex: $flexNo;
}

@mixin displayFlex() {
    display: -webkit-box; 
    display: -moz-box; 
    display: -ms-flexbox; 
    display: -webkit-flex; 
    display: flex;
}

@mixin flexWrap() {
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;	
}

@mixin threeColorGradientAngle($gradientangle: 125deg, $firstColor: #f3f9ff 0%, $secondColor:#f7fbff 44%, $thirdColor: #f0f7ff 100%) {
    background: $firstColor;
    background: -moz-linear-gradient($gradientangle,  $firstColor , $secondColor, $thirdColor); 
    background: -webkit-linear-gradient($gradientangle,  $firstColor, $secondColor, $thirdColor); 
    background: linear-gradient($gradientangle,  $firstColor, $secondColor, $thirdColor); 
}

@mixin threeColorGradient($firstColor: #f3f9ff 0%, $secondColor:#f7fbff 44%, $thirdColor: #f0f7ff 100%) {
    background: $firstColor;
    background: -moz-linear-gradient(top,  $firstColor , $secondColor, $thirdColor); 
    background: -webkit-linear-gradient(top,  $firstColor, $secondColor, $thirdColor); 
    background: linear-gradient(to bottom,  $firstColor, $secondColor, $thirdColor); 
}

@mixin twoColorGradient($firstColor: #f3f9ff 0%, $secondColor:#f7fbff 44%) {
    background: $firstColor;
    background: -moz-linear-gradient(top,  $firstColor , $secondColor); 
    background: -webkit-linear-gradient(top,  $firstColor, $secondColor); 
    background: linear-gradient(to bottom,  $firstColor, $secondColor); 
}

@mixin mobileHeader(){
    .step_header_wrap {
        display: none;                            
        @media (max-width: 767px){
            &.mobile_header_wrap{
                position: fixed;
                bottom: 0;
                z-index: 9;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 50px;
                height: 2px;
                background: #727272;
                margin: auto;
                top: 20px;
            }
            @include displayFlex;
            @include flexWrap;
            @include threeColorGradient(#FDFEFF 0%, #EFF7FF 76%, #DBF1FF 100%);
            @include transition(all, 0.1s ease-in-out);
            justify-content: space-between;
            margin: 0;
                                           
            box-shadow: 0px -7px 15px #00000026;
            border-radius: 15px 15px 0px 0px;
            padding: 30px 0px 0px 25px;
            cursor: pointer;
            width: 100%;
            &.step_header_wrap_bg-beige {                
                @include threeColorGradient(#FFFDF8 0%, #FEF6E4 76%, #FEECCB 100%);
            }
            &.bg-purple-gradient {
                @include threeColorGradient(#EDEBFF 0%, #F6F5FF 76%, #F6F5FF 100%);
            }
            .mobile-step-block {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 9;
                &[aria-expanded="true"] ~ .step_header_wrap_img {
                    position: absolute;
                    right: 0;
                    border: 0;
                    opacity: 0.2;
                  }
                  &[aria-expanded="false"] + .step_header_wrap_title{
                    .more {
                        font-size: 16px;
                    }
                    .less{
                        font-size: 0px;
                    }
                  }
                   
                    &[aria-expanded="true"] + .step_header_wrap_title {
                        .more {
                            font-size: 0px;
                        }
                        .less{
                            font-size: 16px;
                        }
                    }
            }
            
            .step_header_wrap_title {
                padding-top: 15px;
                @include flex-1;
                h2{
                    font-size: 18px;
                    font-weight: 600;
                    margin-right: 15px;
                    padding: 0;
                }
                .listed_points {
                    margin-top: 20px;
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        li{
                            font-size: 16px;
                            font-weight: 400;
                            margin-bottom: 15px;
                            line-height: 19px;
                            position: relative;
                            padding: 0 0 0 10px;
                            &::before{
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0px;
                                width: 3px;
                                height: 17px;
                                background: #27A7DF;
                                border-radius: 15px;
                            }
                        }
                        &.beige-line{
                            li{
                                &::before{
                                    background: #F8B018; 
                                }
                            }
                        }
                        &.scholarship_detail_list {
                            @include displayFlex();
                            @include flexWrap();
                            li:not(:last-child) {
                                margin-right: 30px;
                            }
                        }
                      }
                }
                .step_header_wrap_link {
                    background: transparent;                                        
                    color: #0b3454;
                    text-decoration: none;
                    padding: 3px 20px;
                    display: inline-block;
                    margin-bottom: 18px;
                    font-weight: 500;
                    border: 2px solid #0b3454;
                    @include transition(all, 0.3s, ease-in-out);
                    @include borderRadius(25px);
                    span{
                     @include transition(all, 0.3s, ease-in-out);
                     &.less {
                        font-size: 0;
                      }
                    }

                }
            }
            img{
                height: 128px;
                @media (max-width:575px){
                    height: auto;
                    width: 125px;
                }
            }
        }
    }
}

@mixin formField() {    
    max-width: 100%;
    height: 45px;
    border: 1px solid #b5c2cb;     
    @include borderRadius(6px);
}

@mixin transition($what: all, $time:30s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition:    $what $time $how;
    -ms-transition:     $what $time $how;
    -o-transition:      $what $time $how;
    transition:         $what $time $how;
}

@mixin borderRadius($borderRadius) {
    border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    -moz-border-radius: $borderRadius;
    -ms-border-radius: $borderRadius;
    -o-border-radius: $borderRadius;
}

@mixin translateX($translateX) {
    transform: translateX($translateX);
    -webkit-transform: translateX($translateX);
    -moz-transform: translateX($translateX);
    -ms-transform: translateX($translateX);
    -o-transform: translateX($translateX);
}

@mixin rotate($rotate) {
    transform: rotate($rotate);
    -webkit-transform: rotate($rotate);
    -moz-transform: rotate($rotate);
    -ms-transform: rotate($rotate);
    -o-transform: rotate($rotate);
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacityIE: $opacity * 100;
    filter: alpha(opacity=$opacityIE);
}

@mixin animation($animation...) {
    -webkit-animation: $animation;
    animation: $animation;
}
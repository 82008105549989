* {
    margin: 0;
    padding: 0;
}

.icon-verified:before {
    content: '\e942';
    font-family: 'Material Icons';
}

html,
body {
    margin: 0;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrap {
    flex: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#loadingSpinner {
    width: 3rem;
    height: 3rem;
    animation: spin 2s linear infinite;
}

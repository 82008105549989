@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}
body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.flex-1 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.bg-skyblue-gradient,
.basic-information {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f3f9ff+0,f7fbff+44,f0f7ff+100 */
    background: #f3f9ff; /* Old browsers */
    background: -moz-linear-gradient(top, #f3f9ff 0%, #f7fbff 44%, #f0f7ff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f3f9ff 0%, #f7fbff 44%, #f0f7ff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f3f9ff 0%, #f7fbff 44%, #f0f7ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f9ff', endColorstr='#f0f7ff',GradientType=0 ); /* IE6-9 */
}
.bg-beige-gradient,
.undergraduate-college {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f3f9ff+0,f7fbff+44,f0f7ff+100 */
    background: #fffbf2; /* Old browsers */
    background: -moz-linear-gradient(top, #fffbf2 0%, #fef8ea 44%, #fef7e7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fffbf2 0%, #fef8ea 44%, #fef7e7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fffbf2 0%, #fef8ea 44%, #fef7e7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFBF2', endColorstr='#FEF7E7',GradientType=0 ); /* IE6-9 */
}

/* madhu added */
.bg-purple-gradient {
    background: transparent linear-gradient(144deg, #edebff 0%, #f6f5ff 100%) 0% 0% no-repeat padding-box !important;
}
/* madhu added */
.bg-green-gradient {
    background: transparent linear-gradient(211deg, #fefffd 0%, #ebffe6 76%, #c6ebbe 100%) 0% 0% no-repeat padding-box !important;
}
.bg-pink-gradient {
    background: transparent linear-gradient(145deg, #ffe8eb 0%, #fff8f9 100%) 0% 0% no-repeat padding-box !important;
}

.rightPane.basic-information .rightPaneHeaderWrap {
    background: #f3f9ff;
}
.rightPane.undergraduate-college .rightPaneHeaderWrap {
    background: #fffbf2;
}
.rightPane.bg-purple-gradient .rightPaneHeaderWrap {
    background: #efedff;
}
.bg-green-gradient .rightPaneHeaderWrap {
    background: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.bg-green-gradient .rightPaneHeaderWrap {
    background: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.bg-pink-gradient .rightPaneHeaderWrap {
    background: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.green_recom_bg {
    background: transparent linear-gradient(95deg, #5cae48 0%, #27a7df 100%) 0% 0% no-repeat padding-box !important;
    right: 50px !important;
}
.green_recom_bg_left {
    background: transparent linear-gradient(95deg, #5cae48 0%, #27a7df 100%) 0% 0% no-repeat padding-box !important;
    /* right: 50px !important; */
}
.bg_transparant {
    background: transparent !important;
}

.prize_bg {
    background: transparent linear-gradient(94deg, #51499c 0%, #d42941 100%) 0% 0% no-repeat padding-box !important;
}
.laststep_bg {
    background: transparent linear-gradient(101deg, #51499c 0%, #27a7df 100%) 0% 0% no-repeat padding-box !important;
}

/* madhu added */

.user_name span {
    font-weight: 600;
}
.main-wrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background-color: #fff;
}
.main-wrap > .row {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.leftPane {
    width: 57.82%;
}
.leftPaneHeaderWrap {
    padding: 25px 0px;
    border-bottom: 1px solid #e2e8ef;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: end;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 3;
    height: 93px;
}
.leftPaneHeader {
    max-width: 900px;
    width: 96%;
}
.leftPaneHeader .user_name {
    display: none;
}
.leftPaneHeader img {
    height: 100%;
    width: auto;
}
.leftPaneBodyWrap {
    padding: 50px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: end;
}
.leftPaneBody {
    padding: 0 100px;
    max-width: 890px;
    width: 100%;
}

.leftPaneWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.leftPaneWrap .leftPaneBodyWrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.leftPaneBody .qs_body {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.leftPaneBody .qs_body .step {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.leftPaneBody .qs_body .steps {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.leftPaneBody .qs_body .form-check-inline {
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
}
.leftPaneBody .qs_body .form-check-inline input {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    transition: all 0.3s ease-in-out;
}
.leftPaneBody .qs_body .form-check-inline .form-check-label {
    margin-top: 2px;
}
.progress_svg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    background: white;
    border-radius: 50%;
}
.global {
    position: absolute;
    background: white;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    bottom: -50px;
    right: -50px;
}
.unlock {
    white-space: nowrap;
    display: flex;
    align-items: center;
    background: transparent linear-gradient(96deg, #f05a2a 0%, #f8b018 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px 0px 0px 30px;
    height: 40px;
    right: 20px;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 1;
    -webkit-border-radius: 30px 0px 0px 30px;
    -moz-border-radius: 30px 0px 0px 30px;
    -ms-border-radius: 30px 0px 0px 30px;
    -o-border-radius: 30px 0px 0px 30px;
    width: auto;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.unlock_blue {
    background: transparent linear-gradient(95deg, #27a7df 0%, #51499c 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 30px 0px 0px 30px !important;
}

.unlock .img {
    margin: 0 10px 0 5px;
}
.unlock .img img {
    width: 30px;
    height: 30px;
}
.unlock.prize_bg .img,
.unlock.laststep_bg .img {
    display: flex;
    align-items: end;
    height: 100%;
}
.unlock.unlock_blue .img {
    margin: 0 5px 10px -3px;
}
.unlock.green_recom_bg .img {
    display: flex;
    align-items: end;
    height: 100%;
    padding: 0 0 5px 8px;
}
.unlock.prize_bg .img img {
    width: 62px;
    height: auto;
}
.unlock.laststep_bg .img img {
    width: 48px;
    height: auto;
}
.unlock.green_recom_bg .img img {
    width: 43px;
    height: auto;
}
.unlock.unlock_blue .img img {
    width: 57px;
    height: auto;
}

.unlock.prize_bg .img {
    display: flex;
    align-items: end;
    height: 100%;
}
.unlock.prize_bg .img img {
    width: 62px;
    height: auto;
}
.unlock .text {
    line-height: 18px;
    color: #fff;
    padding-right: 30px;
    font-size: 18px;
    font-weight: 400;
    width: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.progress_svg span {
    position: absolute;
    left: auto;
    right: auto;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 25px !important;
    font-weight: 600;
    color: #000 !important;
    line-height: 90px !important;
}
.progress_svg span::after {
    content: '%';
    font-size: 18px !important;
}
.progress_svg .gaugeMeter {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.global.show .unlock .text {
    width: auto;
}
.global.show .unlock {
    right: 80px;
    opacity: 1;
}

.rightPane {
    width: 42.18%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.rightPaneWrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.rightPaneHeaderWrap {
    min-height: 93px;
    border-bottom: 1px solid #e4e8ef;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.rightPaneHeader {
    max-width: 600px;
    width: 96%;
    display: flex;
    justify-content: end;
    align-items: center;
}
.rightPaneBodyWrap {
    padding: 50px 60px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: sticky;
    top: 93px;
}
.rightPaneBody {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.step {
    display: none;
    flex-direction: column;
}
.step .stepHeader {
    width: 100%;
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding: 0;
}
/* .step .stepHeader::before {content: '';position: absolute;left: 0;bottom: 0;width: 44px;height: 4px;background: #5cae48;border-radius: 5px;} */
.step_img {
    margin: 0;
}
.step_img img {
    width: inherit;
}
.step p {
    width: 100%;
    margin: 0;
    font-size: 18px;
}

.listed_points {
    margin-top: 20px;
}
.step_header_wrap_link {
    display: none;
}
.listed_points ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.listed_points ul li {
    position: relative;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    padding: 0 0 0 10px;
}
/* madhu modified */
.listed_points ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 3px;
    height: 20px;
    background: #27a7df;
    border-radius: 15px;
}
/* madhu modified */

/* madhu added */
.listed_points ul.beige-line li:before {
    background: #f8b018 !important;
}
.listed_points ul.purple-line li:before {
    background: #51499c !important;
}
.listed_points ul.orange-line li:before {
    background: #f05a2a !important;
}
.listed_points ul.green-line li:before {
    background: #5cae48 !important;
}
.listed_points ul.pink-line li:before {
    background: #ee4c61 !important;
}
/* madhu added */

.undergraduate-college .listed_points ul li::before {
    background: #f8b018;
}

.rightPane.basic-information .step_1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.rightPane.undergraduate-college .step_2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.steps {
    /*height: 0px;opacity: 0;overflow: hidden;*/
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
}
.steps.showTab {
    height: auto;
    opacity: 1;
}
.action_btns #viewcareersbtn {
    display: none;
}
/* madhu modified */
.steps_questions {
    padding: 0 0 0 0px;
    margin-top: 0px;
    /* left: -50px; */
    position: relative;
    /* opacity: 0; */
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    max-width: 640px;
}
/* madhu modified */
.steps.showTab .steps_questions {
    /* left: 0px;
    opacity: 1; */
}
.steps_questions h2 {
    font-size: 22px;
    font-weight: 800;
    /* margin-left: 50px; */
    /* opacity: 0; */
    transition: all 0.3s ease-in-out;
}
.steps.showTab h2 {
    margin-left: 0px;
    opacity: 1;
}
.steps_questions h2 span {
    position: relative;
}
.steps_questions h2 span:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #5dae48;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.steps_questions h2 span:before {
    width: 100%;
}
.steps.showTab h2 {
    opacity: 1;
    margin-left: 0;
}
.question_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.question_number {
    margin-right: 10px;
    display: none;
}
.question_number span {
    width: 33px;
    height: 33px;
    border: 2px solid #dddddd;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.question .form-label {
    font-size: 18px;
    font-weight: 500;
    margin-top: 3px;
    margin-bottom: 3px;
}
.question .form-label .info {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #656565;
    border: 1px solid #656565;
    border-radius: 50%;
    font-style: italic;
    font-family: initial;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
}
.question {
    width: 100%;
    position: relative;
}
/* madhu modified */
.main-wrap .form-control {
    max-width: 100%;
    height: 45px;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
    line-height: 30px;
}

.profile_wrap textarea.form-control {
    margin-bottom: 20px;
}
.flex-fill.form-control {
    z-index: inherit;
}
.input-group-text {
    border-color: #b5c2cb;
}
.main-wrap .small-text {
    color: #0b3454;
    font-size: 15px;
}
.main-wrap .form-select {
    max-width: 100%;
    height: 45px;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
}
/* madhu modified */

.form-check-input + label.gap-switch {
    background: #fff;
    padding: 5px;
    border-radius: 25px;
    position: relative;
    width: 100px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    border: 2px solid #f34e65;
    color: #f34e65;
}
.form-check-input:checked + label.gap-switch {
    background: #5dae48;
    border-color: #5dae48;
}
.form-check-input + label.gap-switch:before {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: #f34e65;
    border-radius: 50%;
    content: '';
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + label.gap-switch::before {
    transform: translateX(62px);
    -webkit-transform: translateX(62px);
    -moz-transform: translateX(62px);
    -ms-transform: translateX(62px);
    -o-transform: translateX(62px);
    background: #fff;
}
.form-check-input + label.gap-switch:after {
    content: 'NO';
    color: #f34e65;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + label.gap-switch::after {
    transform: translateX(-30px);
    content: 'YES';
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    color: #fff;
}
.action_btns {
    max-width: 650px;
    background: #f5faff;
    padding: 20px;
    border-radius: 5px;
    margin: 35px 0 0 0px;
    text-align: center;
}
.form-switch .form-check-input:focus,
.form-switch .form-check-input {
    --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23F34E65%27/%3e%3c/svg%3e');
    border: 2px solid #f34e65;
    cursor: pointer;
}
.form-check-input:checked {
    background-color: #29a745;
    border-color: #29a745;
}
.form-switch .form-check-input:checked:focus {
    --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23FFFFFF%27/%3e%3c/svg%3e');
    border: 2px solid #29a745;
}
.action_btns button.nextbtn {
    padding: 0px 30px;
    font-size: 1.5em;
    width: 180px;
    cursor: pointer;
    border: 0px;
    position: relative;
    margin: auto;
    transition: all 0.25s ease;
    background: #0b3454;
    color: #fff;
    overflow: hidden;
    border-radius: 25px;
    height: 45px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}

.load {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -ms-border-radius: inherit;
    -o-border-radius: inherit;
}
.load::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px solid #fff;
    width: 30px;
    height: 30px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    animation: loading1 1s ease infinite;
    z-index: 10;
    -webkit-animation: loading1 1s ease infinite;
}
.load::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px dashed #fff;
    width: 30px;
    height: 30px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    animation: loading1 2s linear infinite;
    z-index: 5;
    -webkit-animation: loading1 2s linear infinite;
}

@keyframes loading1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}
.float_horizontal_animate {
    transform: translatex(0px);
    animation: float_horizontal 4s ease-in-out infinite;
    -webkit-animation: float_horizontal 4s ease-in-out infinite;
}

@keyframes float_horizontal {
    0% {
        transform: translatex(0px);
        -webkit-transform: translatex(0px);
        -moz-transform: translatex(0px);
        -ms-transform: translatex(0px);
        -o-transform: translatex(0px);
    }
    50% {
        transform: translatex(-10px);
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -ms-transform: translatex(-10px);
        -o-transform: translatex(-10px);
    }
    100% {
        transform: translatex(0px);
        -webkit-transform: translatex(0px);
        -moz-transform: translatex(0px);
        -ms-transform: translatex(0px);
        -o-transform: translatex(0px);
    }
}

button .loading {
    opacity: 0;
    visibility: hidden;
}
button.activeLoading .loading {
    visibility: visible;
    opacity: 1;
}

.question .form-select.max-100 {
    max-width: 100%;
}

.check_box {
    background: #f5f7f8 0% 0% no-repeat padding-box;
    border-radius: 6px;
    min-height: 48px;
    padding: 12px 20px 10px;
    margin: 20px 0 -35px 0;
    max-width: 640px;
}

.check_box .form-check-input {
    width: 20px;
    height: 20px;
    vertical-align: top;
    display: inline-block;
    margin-top: 0.5px;
    border: 1px solid #0b3454cc;
}
.check_box .form-check-input:checked {
    background-color: #5dae48;
    border-color: #5dae48;
}
.check_box .form-check-input:focus {
    box-shadow: none;
}

.datewrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
    height: 45px;
    position: relative;
    overflow: hidden;
}
.daterangePickerList,
.singledaterangePickerList {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    opacity: 0;
}
.datewrap .arrow {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*.datewrap .arrow span {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3e%3c/svg%3e");font-size: 0;width: 25px;height: 25px;}*/
/* .datewrap .arrow span {
    background: url(../images/arrow.svg) no-repeat center;
    font-size: 0;
    width: 25px;
    height: 25px;
} */
.datewrap .from,
.datewrap .to {
    flex: 1;
}
/* .datewrap .form-control {
    height: 100% !important;
    border: 0 !important;
    background: #fff url(../images/calendar.svg) no-repeat 10px center;
} */

/* madhu added */
.form-control[type='date'] {
    background: #fff url(../images/calender.svg) no-repeat right 10px center;
    text-align: left;
    /* padding-left: 35px; */
    padding-right: 5px;
    position: relative;
}
.form-control[type='date']::-webkit-calendar-picker-indicator,
.form-control[type='date']::-moz-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}
.form-control.form-date {
    background-image: url(../images/calender.svg);
    background-position: center right 15px;
    background-repeat: no-repeat;
}
.form-control.form-search {
    background-image: url(../images/search.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 34px;
}
/* madhu added */

.form-control.form-linkedin {
    background-image: url(../images/linkedin.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}
.form-control.form-facebook {
    background-image: url(../images/facebook.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}
.form-control.form-instagram {
    background-image: url(../images/instagram.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}
.form-control.form-twitter {
    background-image: url(../images/twitter.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}

.datepicker {
    max-width: 280px;
    width: 100%;
    padding: 0;
}
.datepicker-years {
    overflow: hidden;
    border-radius: 5px;
}
.datepicker .table-condensed {
    width: 100%;
}
.datepicker table tr td span {
    height: auto;
    line-height: 35px;
    border-radius: 25px;
}
.datepicker thead {
    background: #f4faff;
    line-height: 40px;
}
.datepicker table tr td span.active.active {
    background: #5dae48;
}

.prev {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 16px 12px !important;
    font-size: 0;
    width: 50px !important;
}
.opacity-0 {
    opacity: 0;
}
.next {
    background-image: url("data:; image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 16px 12px !important;
    font-size: 0;
    width: 50px !important;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.datepicker .datepicker-switch {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 5px;
}

.form-check.custom-rectbox-radio .form-check-input + label {
    width: 100%;
    height: 45px;
    font-weight: 500;
    border: 1px solid #b9c2cb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.form-check.custom-rectbox-radio .form-check-input:checked + label {
    background: #5dae48;
    border: 1px solid #5dae48;
    color: #fff;
}
.custom-rectbox-radio {
    padding: 0;
    margin: 0;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.custom-rectbox-radio:not(:last-child) {
    margin-right: 8px;
}
.radio_parent {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.form-check.custom-rectbox-radio .form-check-input + label:before {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 24px;
    background: url(../images/male_icon.svg) no-repeat center;
}
.form-check.custom-rectbox-radio .form-check-input + label[for='female']:before {
    background: url(../images/female_icon.svg) no-repeat center;
}
.form-check.custom-rectbox-radio .form-check-input:checked + label::before {
    filter: invert(1) brightness(100);
    -webkit-filter: invert(1) brightness(100);
}
.step_header_wrap {
    display: none;
}

.header_container {
    max-width: 1500px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0px;
    width: 96%;
    flex-wrap: wrap;
}
.ful-header {
    border-bottom: 1px solid #e2e8ef;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}
.header_container .user_name {
    font-size: 20px;
}
.rightSection_header {
    position: relative;
}
.global_wrap {
    position: relative;
    margin-left: auto;
    height: 100%;
    margin-right: 0px;
}
.global_wrap .global {
    right: 0;
    top: 0px;
    bottom: auto;
}

.scholarship_banner {
    background: transparent linear-gradient(262deg, #fffdf8 0%, #fef6e4 76%, #feeccb 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    height: 100%;
}
.scholarship_container {
    max-width: 1066px;
    width: 90%;
    margin: auto;
    padding-top: 0px;
}
.scholarship_container h2 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 55px;
}
.scholarship_container h2 span {
    position: relative;
}
.scholarship_container h2 span:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #5cae48;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.scholarship_detail_list {
    margin: 24px 0 20px 0;
    padding: 0;
    list-style: none;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    flex-wrap: wrap;
}
.scholarship_detail_list li:not(:last-child) {
    margin-right: 30px;
}
.scholarship_detail_list li {
    position: relative;
    padding-left: 27px;
    margin-bottom: 16px;
}
.scholarship_detail_list li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 18px;
    background: url(../images/tick.svg) no-repeat 0 0;
}
.scholarship_detail_carousel_container {
    max-width: 1066px;
    margin: auto;
    width: 90%;
}
.scholarship_detail_carousel {
    width: 100%;
    padding: 40px 0;
}
.joined_offer_wrap {
    position: relative;
    margin-left: 0;
    margin-bottom: 15px;
    display: flex;
}
.joined_offer_wrap:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    background: #5cae48;
    height: 1px;
}
.joined_label {
    position: relative;
    background: #5cae48 0% 0% no-repeat padding-box;
    border-radius: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 400;
    padding: 7px 20px 7px 7px;
    font-size: 14px;
}
.joined_label i {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    margin-right: 7px;
    position: relative;
}
.joined_label i:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;
    border-left: 2px solid #5cae48;
    border-bottom: 2px solid #5cae48;
    transform: rotate(-45deg);
    left: 5px;
    top: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

/* madhu added */
.joined_label_noline:before {
    display: none !important;
}
.w-65 {
    width: 65%;
}
/* madhu added */

.offered-univarsity-name {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
}
.joined_label.didnt-join {
    background: #0b3454;
}
.joined_label.didnt-join i:before {
    border-left: 2px solid #0b3454;
    border-bottom: 2px solid #0b3454;
}
.joined_offer_wrap.didnt-join:before {
    background: #0b3454;
}

.scholarship_block {
    width: 350px;
}

.card_profiles {
    background: transparent linear-gradient(144deg, #edebff 0%, #f6f5ff 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    padding: 1px;
    height: auto;
    max-height: initial;
    min-height: initial;
}

/* madhu added */
.card_profiles_pink {
    background: transparent linear-gradient(145deg, #ffe8eb 0%, #fff8f9 100%) 0% 0% no-repeat padding-box !important;
}
/* madhu added */

.card_profiles:first-child {
    margin: 0 0 10px;
}
.card_profiles .card_profiles_univarsity {
    padding: 19px 19px 13px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.card_profiles .card_profiles_univarsity .card_profiles_name {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail {
    flex: 1;
}
.card_profiles .card_profiles_univarsity .card_profiles_name > a {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
    width: 70px;
    height: 70px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_profiles .card_profiles_univarsity .card_profiles_name a img {
    width: auto;
    height: 100%;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail h2 {
    font-size: 22px;
    font-weight: 600;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail h2 a {
    border-radius: 0;
    text-decoration: none;
    color: #000;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail h2 span {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
    color: #0b3454;
}
.carousel-indicators .dot {
    width: 10px;
    height: 10px;
    background: #0b34541a;
    border-radius: 50%;
    margin: 0 4px;
    padding: 0 !important;
    border: 0;
}
.carousel-indicators .dot.active {
    background: #0b3454b3;
}
.scholarship_block > h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 10px;
}
.scholarship_block > h3 span {
    font-size: 18px;
    font-weight: 600;
    display: block;
}
.scholarship_amt_detail {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 6px 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    -webkit-border-radius: 0px 0px 6px 6px;
    -moz-border-radius: 0px 0px 6px 6px;
    -ms-border-radius: 0px 0px 6px 6px;
    -o-border-radius: 0px 0px 6px 6px;
}
.scholarship_amt {
    font-size: 17px;
    font-weight: 700;
}
.scholarship_label {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.scholarship_label i {
    width: 37px;
    height: 25px;
    display: inline-block;
    background: url(../images/scholarships-hat.jpg) no-repeat 0 0;
    margin-right: 10px;
}

.univarsity-block {
    display: flex;
    justify-content: center;
    margin-top: 3px;
}
.univarsity-block .univarsity-block-cover {
    display: flex;
    align-items: center;
    max-width: 255px;
    width: 100%;
}
.card_profiles .card_profiles_univarsity .card_profiles_name a img {
    width: auto;
    height: 100%;
}
.univarsity-block .univarsity-block-cover > a {
    width: 67px;
    height: 67px;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    margin-right: 10px;
}
.univarsity-block .univarsity-block-cover > a img {
    width: auto;
    height: 100%;
}
.univarsity-block .univarsity-block-cover h3 {
    flex: 1;
    margin: 0;
}
.univarsity-block .univarsity-block-cover h3 p {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 3px;
}
.univarsity-block .univarsity-block-cover h3 p a {
    text-decoration: none;
    color: #000;
}
.univarsity-block .univarsity-block-cover h3 .course {
    font-size: 15px;
    font-weight: 500;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
}
.univarsity-block .univarsity-block-cover h3 .course .img-fluid {
    width: 16px;
    margin-right: 7px;
    margin-top: 1px;
}
.univarsity-block .univarsity-block-cover h3 .course span {
    width: calc(100% - 23px);
}

.scholarship_carousel .owl-dots {
    display: flex;
    justify-content: center;
    margin: 15px;
}
.scholarship_carousel .owl-dots .owl-dot {
    width: 8px;
    height: 8px;
    background: #0b34541a;
    border-radius: 50%;
    margin: 0 4px;
}
.scholarship_carousel .owl-dots .owl-dot.active {
    background: #0b3454b3;
}

#skip_pop .modal-dialog {
    max-width: 600px;
    border-radius: 10px;
}
#skip_pop .modal-content {
    border-radius: 10px;
}
#skip_pop .modal-dialog .modal-body {
    padding: 40px 0 0 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 10px;
    min-height: 300px;
}
.img_pop {
    position: relative;
    z-index: 1;
    float: right;
}
#skip_pop .modal-dialog .modal-body h4 {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
}
#skip_pop .modal-dialog .modal-body h4::before {
    content: '';
    position: absolute;
    bottom: -17px;
    width: 50px;
    height: 3px;
    background: #5cae48;
    border-radius: 5px;
}
#skip_pop .modal-dialog .modal-body p {
    font-size: 20px;
    line-height: 28px;
    max-width: 318px;
    margin: 40px 0 0;
    font-weight: 500;
}
#skip_pop .img_pop {
    position: relative;
    z-index: 1;
    float: right;
    margin-top: -30px;
    width: 220px;
    text-align: right;
    display: flex;
    align-items: end;
}
#skip_pop .modal-footer {
    background: transparent linear-gradient(261deg, #fdfeff 0%, #eff7ff 16%, #d4ffc9 100%) 0% 0% no-repeat padding-box;
    border-radius: 90px 10px 10px 10px;
    opacity: 1;
    display: flex;
    justify-content: start;
    padding-left: 70px;
    border: 0;
    min-height: 90px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -90px;
    -webkit-border-radius: 90px 10px 10px 10px;
    -moz-border-radius: 90px 10px 10px 10px;
    -ms-border-radius: 90px 10px 10px 10px;
    -o-border-radius: 90px 10px 10px 10px;
}
.btn-goback {
    background: #5cae48;
    border-radius: 31px;
    width: 158px;
    height: 50px;
    color: #fff;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    text-decoration: none;
}
.btn-goback:hover {
    background: #27970b;
    color: #fff;
}
.btn-later {
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-later:hover {
    color: #f34e65;
}
.btn-skip {
    margin: 15px;
    font-size: 12px;
    text-decoration: navajowhite;
    font-weight: 500;
    color: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    background: no-repeat;
    border: 0;
}
.btn-skip:hover {
    color: #d42941;
}
.modal-backdrop.show {
    opacity: 0.8;
}

.multiple_benefits_banner {
    background: transparent linear-gradient(262deg, #fdfeff 0%, #eff7ff 76%, #dbf1ff 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    padding-top: 24px;
}
.multiple_benefits_container {
    max-width: 990px;
    margin: auto;
    width: 90%;
}
.multiple_benefits_container h2 {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    margin: 37px 0 25px;
    line-height: 24px;
}
.multiple_benefits_container h2:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #5cae48;
    left: 0;
    bottom: -5px;
}
.multiple_benefits_container p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    max-width: 490px;
}

.multiple_benefits_cards_container {
    max-width: 990px;
    width: 90%;
    margin: auto;
}
.multiple_benefits_cards {
    width: 100%;
    padding: 30px 0;
}
.multiple_benefits_cards_container .card {
    border: 0;
    border-radius: 6px;
    /* overflow: hidden; */
    height: 100%;
}
.multiple_benefits_cards_container .card.network-card {
    background: #fff7f8;
}
.multiple_benefits_cards_container .card.event-card {
    background: #f9f8ff;
}
.multiple_benefits_cards_container .card .card-header {
    padding: 18px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 144px;
}
.multiple_benefits_cards_container .card .card-header .title {
    font-size: 20px;
    line-height: 22px;
    flex: 1;
    color: #fff;
}
.multiple_benefits_cards_container .card .card-header .card-img {
    width: auto;
}
.multiple_benefits_cards_container .card.network-card .card-header {
    background: #d42941;
}
.multiple_benefits_cards_container .card.event-card .card-header {
    background: #51499c;
}
.multiple_benefits_cards_container .card .card-body {
    padding: 0;
}
.card .card-body .benefits_toggle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.card.network-card .card-body .benefits_toggle {
    background: #fce4e7;
}
.card.event-card .card-body .benefits_toggle {
    background: #e8e7f0;
}
.multiple_benefits_cards_container .card .card-body .benefits_toggle .btn {
    width: 100%;
    border-radius: 0;
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 30px;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    border: 0;
    justify-content: space-between;
}
.benefits_toggle .btn:focus {
    box-shadow: none;
}
.benefits_toggle .toggle {
    width: 15px;
    height: 15px;
    position: relative;
}
.benefits_toggle .toggle::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 3px;
    background: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.benefits_toggle .toggle::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 3px;
    height: 15px;
    background: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.benefits_toggle .btn[aria-expanded='true'] .toggle::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.multiple_benefits_cards_container .card.network-card .card-body .benefits_toggle .btn {
    background: #fce4e7;
}
.benefit-list {
    padding: 0px 30px 26px;
}
.multiple_benefits_cards_container .card .card-body .benefit_form {
    padding: 20px 30px 40px;
}
.benefit-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 18px;
    line-height: 22px;
}
.benefit-list ul li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px;
}
.benefit-list ul li:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 6px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
    left: 0;
    top: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.video-btn {
    background: #0b3454 0% 0% no-repeat padding-box;
    border-radius: 25px;
    padding: 9px 20px;
    display: inline-flex;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    border: 0;
}
.video-btn b {
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    margin: 0 0 0 7px;
    display: inline-block;
    font-size: 0;
    position: relative;
}
.video-btn b::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 5.5px;
    margin: auto;
    width: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #0b3454;
    height: 0;
}
.video-btn:hover {
    color: #fff;
    background: #5bae47;
}
.video-btn:hover b::before {
    border-left: 5px solid #5bae47;
}
.form-label {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 0 0 4px;
}
.select-option.first-option,
.select-option.second-option {
    position: relative;
}
.select-option.first-option .form-select,
.select-option.second-option .form-select {
    margin-bottom: 20px;
}
.form-check-input:checked ~ .select-option.first-option:after {
    content: '';
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #00000016;
    width: 208%;
    left: 0;
}
.form-check-input:checked ~ .select-option.second-option:after {
    content: '';
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #00000016;
    width: 208%;
    right: 0;
}
.benefit_form .form-check {
    margin: 0;
    padding: 0;
}
.benefit_form .form-check .form-check-input[role='switch'] {
    margin-left: 0;
}
.custom_radius_check {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0b3454;
    border-radius: 25px;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom_radius_check i {
    width: 17px;
    height: 12px;
    background: url(../images/check-tick.svg) no-repeat 0 0;
    filter: invert(76%) sepia(79%) saturate(0%) hue-rotate(131deg) brightness(94%) contrast(96%);
    -webkit-filter: invert(76%) sepia(79%) saturate(0%) hue-rotate(131deg) brightness(94%) contrast(96%);
}
.select-option {
    display: none;
    width: 100%;
    position: relative;
}
.form-check-input:checked ~ .select-option {
    display: inline-block;
}
.select-option .form-select {
    margin-top: 16px;
    border: 1px solid #0b3454;
    border-radius: 6px;
    height: 42px;
}
.form-check-input:checked + .custom_radius_check {
    background: #5cae48;
    border-color: #5cae48;
    color: #fff;
}
.form-check-input:checked + .custom_radius_check i {
    filter: invert(1) brightness(1);
    -webkit-filter: invert(1) brightness(1);
}
.custom_radius_check::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-top: 7px solid transparent;
    width: 0;
    margin: auto;
    top: 85%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    opacity: 0;
}
.form-check-input:checked + .custom_radius_check::after {
    top: 100%;
    border-top: 7px solid #5cae48;
    opacity: 1;
}
.multiple_benefits_cards_container .action_btns {
    max-width: 100%;
    margin: 0;
}
.bg-drop {
    display: none;
}
footer.footer {
    padding: 15px 0;
    border-top: 1px solid #eee;
    background: #fff;
    position: relative;
    height: auto;
}
.footer-wrap {
    width: 90%;
    margin: auto;
    max-width: 1500px;
}
.powerby {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 139px;
}

.pop-dialog {
    max-width: 700px;
    width: 90%;
    margin: auto;
}
.modal.show .modal-dialog .pop-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.pop-footer {
    background: transparent linear-gradient(261deg, #fdfeff 0%, #eff7ff 16%, #d4ffc9 100%) 0% 0% no-repeat padding-box;
    border-radius: 90px 0px 10px 10px;
    opacity: 1;
    padding: 20px 66px;
    margin-left: 10px;
    -webkit-border-radius: 90px 0px 10px 10px;
    -moz-border-radius: 90px 0px 10px 10px;
    -ms-border-radius: 90px 0px 10px 10px;
    -o-border-radius: 90px 0px 10px 10px;
}
.pop-body {
    min-height: 255px;
    padding: 80px 50px 0;
}
.pop-body .img_pop {
    position: absolute;
    right: -38px;
    bottom: 0;
}
.pop-dialog h4 {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    margin: 0 0 35px;
}
.pop-dialog h4::before {
    content: '';
    position: absolute;
    bottom: -12px;
    width: 50px;
    height: 3px;
    background: #5cae48;
    border-radius: 5px;
}
.pop-body p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}
.pop_step {
    padding: 0;
    margin: 40px 0 0;
    list-style: none;
}
.pop_step li {
    padding: 0 0 30px 53px;
    line-height: 22px;
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
}
.pop_step li b {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 7px;
}
.pop_step li .completed_li {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #5cae48;
    left: 0;
    top: -7px;
    margin: 0 8px;
}
.pop_step li .completed_li:before {
    content: '';
    position: absolute;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    width: 12px;
    height: 7px;
    left: 6px;
    top: 7px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.pop_step li .edit_li {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #0b3454 url(../images/blue_edit.png) no-repeat center;
    left: 0;
    top: 0;
    border-radius: 50%;
}
.pop_step li.pop_completed::before {
    content: '';
    position: absolute;
    top: 0;
    left: 19px;
    background: #5cae48;
    width: 2px;
    bottom: 0;
    margin: auto;
}

.mentoring_contact i {
    width: 36px;
    height: 36px;
    background: #27a7df;
    display: flex;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
}
.btn-update {
    border: none;
    padding: 10px 30px;
    background: #5cae48;
    border-radius: 25px;
    color: #ffff;
    text-transform: uppercase;
    font-weight: 500;
    font-weight: 14px;
    transition: all 0.3s ease-in-out;
}
.form-control {
    max-width: 100%;
    height: 45px;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
}
textarea.form-control {
    min-height: 60px;
}
.btn-update:hover {
    background: #43992e;
}
.edit_phone .btn-close {
    font-size: 12px;
}
.btn-cancel {
    background: none;
    border: 0;
    padding: 10px 5px;
    border-radius: 25px;
    margin-left: 10px;
    font-weight: 500;
    color: #828282;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-cancel:hover {
    color: #000;
}

@media (max-width: 1199px) {
    /* .global {bottom: -60px;} */
    .global.show .unlock {
        white-space: normal;
        width: 310px;
    }
    .global.show .unlock .text {
        line-height: 17px;
        font-size: 16px;
    }
    .cta_add {
        flex-direction: column;
    }
    .cta_add .btn-add {
        margin-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .leftPaneBody {
        padding: 0 50px;
    }
    .rightPaneBodyWrap {
        padding: 50px 40px;
    }
    .action_btns button.nextbtn {
        width: 150px;
    }
    .scholarship_block {
        width: 100%;
    }
    .scholarship_carousel .item {
        display: flex;
        justify-content: space-between;
    }
    .scholarship_carousel .item .card_profiles {
        width: 49%;
        display: flex;
        flex-direction: column;
    }
    .item-wrap {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .item-wrap .card_profiles {
        width: 49%;
        margin: 0;
    }
}

@media (min-width: 768px) and (max-width: 992.98px) {
    .form-check-input:checked ~ .select-option.first-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .form-check-input:checked ~ .select-option.second-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        right: -10%;
    }
    .form-check-input:checked ~ .select-option.third-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .select-option.third-option .form-select {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .leftPane {
        width: 100%;
    }
    .leftPaneBody {
        max-width: 100%;
        padding: 30px;
    }
    .leftPaneBodyWrap {
        padding: 0px 0 50px;
    }
    .rightPane {
        display: block;
        width: 100%;
        box-shadow: 0px -7px 15px #00000026;
        border-radius: 15px 15px 0px 0px;
        padding: 45px 0px 0px 25px;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 7;
    }
    .rightPane.basic-information .rightPaneHeaderWrap {
        display: none;
    }
    .rightPaneBodyWrap {
        padding: 0;
        position: static;
    }
    .rightPane.basic-information {
        background: transparent linear-gradient(254deg, #fdfeff 0%, #eff7ff 76%, #dbf1ff 100%) 0% 0% no-repeat padding-box;
    }
    .rightPane.basic-information.undergraduate-college {
        background: transparent linear-gradient(213deg, #fffdf8 0%, #fef6e4 76%, #feeccb 100%) 0% 0% no-repeat padding-box !important;
    }
    .rightPane.basic-information .step_1 {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
    }
    .step_img img {
        width: auto;
        height: 128px;
    }
    .mobile-step-block[aria-expanded='true'] ~ .step_img {
        position: absolute;
        right: 0;
        border: 0;
        opacity: 0.2;
    }
    .textDetail {
        flex: 1;
    }
    .action_btns {
        max-width: 90%;
        margin: auto;
        width: 100%;
    }
    .action_btns.social {
        margin: auto;
    }
    .custom-rectbox-radio {
        flex: none;
        width: 130px;
    }
    /* madhu modified */
    .step_header_wrap {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        background: transparent linear-gradient(254deg, #fdfeff 0%, #eff7ff 76%, #dbf1ff 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px -7px 15px #00000026;
        border-radius: 15px 15px 0px 0px;
        padding: 45px 0px 0px 25px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -ms-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        align-items: end;
    }
    /* madhu modified */

    /* madhu added */
    .step_header_wrap_bg-beige {
        background: transparent linear-gradient(213deg, #fffdf8 0%, #fef6e4 76%, #feeccb 100%) 0% 0% no-repeat padding-box !important;
    }
    /* madhu added */

    .step_header_wrap::before,
    .rightPane::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 50px;
        height: 2px;
        background: #727272;
        margin: auto;
        top: 20px;
    }

    .step_header_wrap_img img {
        height: 128px;
    }
    .step_header_wrap_title {
        padding-top: 15px;
        flex: 1;
    }
    .steps.showTab > h2 {
        padding: 40px 40px 0;
    }
    .steps_questions {
        /* padding: 0px 40px 40px; */
        margin-top: 30px;
        max-width: 100%;
    }
    .leftPaneHeaderWrap {
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .leftPaneHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 94%;
        margin: auto;
    }
    .leftPaneHeader .user_name {
        display: inline-block;
        font-size: 18px;
    }
    .check_box {
        margin: 20px auto -35px;
        max-width: 680px;
        width: 90%;
    }
    /* madhu modified */
    .step_header_wrap_title > h2 {
        font-size: 18px;
        font-weight: 600;
        margin-right: 15px;
    }
    /* madhu modified */
    .step_header_wrap_link {
        background: transparent;
        border-radius: 27px;
        color: #0b3454;
        text-decoration: none;
        padding: 3px 20px;
        transition: all 0.3s ease-in-out;
        display: inline-block;
        margin-bottom: 18px;
        font-weight: 500;
        border: 2px solid #0b3454;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-border-radius: 27px;
        -moz-border-radius: 27px;
        -ms-border-radius: 27px;
        -o-border-radius: 27px;
        display: inline-block;
        margin-top: 16px;
    }
    .step_header_wrap_link span {
        transition: all 0.3s ease-in-out;
    }
    .step_header_wrap_link .less {
        font-size: 0;
    }
    .step_header_wrap_link:hover {
        background: #0b3454;
        color: #fff;
    }
    .step_header_wrap.open .step_header_wrap_link {
        margin-bottom: 20px;
    }

    /* madhu modified */
    .listed_points:not(.show) {
        height: 0;
        overflow: hidden;
    }
    .listed_points ul li {
        font-size: 16px;
        font-size: 16px;
        margin-bottom: 15px;
        line-height: 19px;
    }
    .listed_points ul li:last-child {
        margin-bottom: 0;
    }
    /* madhu modified */

    .mobile-step-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
    }
    .mobile-step-block[aria-expanded='false'] + .step_header_wrap_title .step_header_wrap_link .more,
    .mobile-step-block[aria-expanded='false'] + .textDetail .step_header_wrap_link .more {
        font-size: 16px;
    }
    .mobile-step-block[aria-expanded='false'] + .step_header_wrap_title .step_header_wrap_link .less,
    .mobile-step-block[aria-expanded='false'] + .textDetail .step_header_wrap_link .less {
        font-size: 0;
    }
    .mobile-step-block[aria-expanded='true'] + .step_header_wrap_title .step_header_wrap_link .more,
    .mobile-step-block[aria-expanded='true'] + .textDetail .step_header_wrap_link .more {
        font-size: 0px;
    }
    .mobile-step-block[aria-expanded='true'] + .step_header_wrap_title .step_header_wrap_link .less,
    .mobile-step-block[aria-expanded='true'] + .textDetail .step_header_wrap_link .less {
        font-size: 16px;
    }
    .step_header_wrap_title .listed_points ul li::before {
        height: 17px;
    }

    .global {
        width: 100%;
        height: 40px;
        position: relative;
        margin-bottom: -26px;
        background: none;
        border-radius: 0;
        margin-top: 25px;
        border-top: 1px solid #e2e8ef;
        bottom: auto;
        right: auto;
    }
    .global .unlock,
    .global.show .unlock {
        width: 100%;
        right: 0;
        bottom: 0;
        border-radius: 0;
        position: static;
        opacity: 1;
    }
    .progress_svg {
        width: 70px;
        height: 70px;
        position: absolute;
        right: 30px;
        top: auto;
        bottom: 0px;
        background: #fff;
        padding: 0;
        border-radius: 50%;
        box-shadow: 0px 0px 10px 0 #d3d3d3;
    }
    .progress_svg .gaugeMeter {
        width: 60px !important;
        height: 60px;
    }
    .progress_svg .gaugeMeter canvas {
        width: 100%;
        height: 100%;
    }
    .progress_svg span {
        line-height: 60px !important;
        font-size: 18px !important;
    }
    .global_wrap {
        width: 100%;
        order: 1;
    }
    .global.show .unlock,
    .global .unlock {
        white-space: normal;
    }
    .global.show .unlock.prize_bg,
    .global .unlock.prize_bg {
        width: auto;
        margin: 0 -2%;
    }
    .global.show .unlock .text,
    .global .unlock .text {
        width: calc(100% - 140px);
    }
    .global .unlock.unlock_blue {
        border-radius: 0 !important;
    }

    /* madhu modified */
    .global .unlock .text {
        font-size: 16px;
        font-weight: 500;
    }
    /* madhu modified */

    .scholarship_carousel .item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .scholarship_block {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 30px;
        position: relative;
        z-index: 0;
    }
    .scholarship_carousel .item .card_profiles {
        width: 48%;
        margin: 0 0 10px;
        display: flex;
        flex-direction: column;
    }
    .scholarship_amt {
        font-size: 18px;
        font-weight: 700;
    }
    .scholarship_amt_detail {
        padding: 20px 15px;
    }
    /* madhu modified */
    .bg-drop {
        position: fixed;
        left: 0;
        right: 0;
        top: 100%;
        bottom: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.7);
        z-index: 6;
        transition: all 0.1s ease-in-out;
        display: block;
    }
    /* madhu modified */
    .bg-drop[aria-expanded='true'] {
        top: 0;
    }

    .mobile-step-block[aria-expanded='true'] ~ .step_header_wrap_img {
        position: absolute;
        right: 0;
        border: 0;
        opacity: 0.2;
    }
    .scholarship_detail_carousel {
        padding: 0;
    }
    .leftPaneHeaderWrap .global.nobadge {
        height: 0;
    }
    .leftPaneHeaderWrap .global.nobadge .progress_svg {
        top: -30px;
    }
    .global_wrap .global.nobadge {
        height: auto;
        border: 0;
    }
    .global_wrap .global.nobadge .progress_svg {
        top: -30px;
    }
    footer {
        margin-bottom: 160px;
    }

    .pop-body .img_pop {
        position: static;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 575px) {
    .ful-header + .main-wrap {
        padding: 30px;
    }
    .leftPaneBody {
        padding: 30px;
    }
    /* madhu added */
    .listed_points {
        margin-top: 10px;
        margin-right: 15px;
    }
    /* madhu added */

    .leftPaneHeader img {
        width: 130px;
    }
    .header_container img {
        width: 130px;
    }
    .step_header_wrap_title {
        padding-top: 0;
    }
    .steps.showTab > h2 {
        padding: 30px 25px 0;
    }
    .steps_questions {
        /* padding: 0px 25px 30px; */
    }
    .action_btns {
        max-width: 100%;
        margin: 0 auto;
        padding: 10px;
    }
    .step_header_wrap_img img {
        height: 100px;
        animation: none;
        -webkit-animation: none;
    }
    .leftPaneHeader .user_name span {
        font-weight: 600;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        vertical-align: top;
    }
    .global .unlock {
        padding: 12px 0;
    }
    .global {
        height: auto;
    }
    .global .unlock {
        height: auto;
    }
    .progress_svg {
        bottom: 10px;
        right: 10px;
    }
    .global_wrap .progress_svg {
        right: 0;
        bottom: 5px;
    }
    .scholarship_carousel .item .card_profiles {
        width: 100%;
        margin: 0 0 10px;
    }
    .scholarship_detail_carousel_container {
        width: 100%;
        overflow: hidden;
    }
    .joined_offer_wrap {
        margin-left: 0;
    }
    .scholarship_block {
        width: 100%;
        margin: auto;
    }
    .scholarship_detail_carousel {
        padding: 0px 0 40px;
    }
    .global_wrap .global {
        top: 0;
        left: 0px;
    }
    .skip_pop .brife {
        flex: 1;
    }
    #skip_pop .modal-footer {
        margin: 0;
    }
    #skip_pop .modal-dialog .modal-body {
        padding: 30px 0 0 30px;
    }
    .action_btns .btn-skip {
        margin: 18px 18px 0;
        display: inline-block;
    }
    #skip_pop .img_pop {
        margin-top: 15px;
        text-align: center;
        width: 100%;
        padding-right: 30px;
    }
    .form-check-input:checked ~ .select-option.first-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .form-check-input:checked ~ .select-option.second-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        right: -10%;
    }
    .form-check-input:checked ~ .select-option.third-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .select-option.third-option .form-select {
        margin-bottom: 20px;
    }
    .global.nobadge {
        top: auto;
        bottom: auto;
        height: 20px;
        border: 0;
    }
    .global.nobadge .progress_svg {
        top: -23px;
        bottom: auto;
        right: 0;
    }
    .leftPaneHeaderWrap .global.nobadge .progress_svg {
        right: 10px;
        top: -23px;
    }
    .global.show .unlock.prize_bg,
    .global .unlock.prize_bg,
    .global .unlock.laststep_bg {
        height: 50px;
    }
    .unlock.prize_bg .img,
    .unlock.laststep_bg .img {
        margin-bottom: -20px;
    }
    .leftPaneHeaderWrap .progress_svg {
        top: -22px;
    }
    .global_wrap .global.nobadge .progress_svg {
        top: -23px;
    }
    .global_wrap .global.nobadge {
        padding: 10px;
    }
    .global .unlock.green_recom_bg {
        padding: 7px 0;
    }
    .global .unlock.green_recom_bg .text {
        width: calc(100% - 0px);
        padding: 0 20px 0 0;
    }
    .global .unlock.unlock_blue {
        padding: 0;
    }
    .pop-body {
        padding: 30px 30px 0;
    }
    .edit_phone .btn-close {
        font-size: 12px;
        position: absolute;
        top: 0px;
        right: -10px;
    }
    .step_img img {
        height: auto;
        width: 125px;
    }
    .item-wrap .card_profiles {
        width: 100%;
        margin: 0 0 20px;
    }
}

@media (max-width: 400px) {
    .global.show .unlock .text {
        line-height: 16px;
        font-size: 13px;
    }
}

/* madhu added */

.cta_add {
    display: flex;
    font-size: 16px;
    font-weight: 500;
}
.cta_add .form-check label {
    color: #0b3454;
}
.cta_add .form-check input {
    border: 2px solid #0b3454;
    width: 22px;
    height: 22px;
    border-radius: 40px;
    margin-right: 10px;
}
.cta_add .form-check-input:checked {
    background-color: #27970b;
    border: 0 !important;
}
.cta_add .form-check label {
    padding-top: 3px;
    color: #0b3454;
}
.cta_add .btn-add {
    color: #27a7df;
    padding: 0 30px 0 0;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.cta_add .btn-add:hover {
    color: #5cae48;
}
.cta_add .btn-add i {
    margin-right: 10px;
    margin-top: -4px;
}
.cta_add .btn-add i path {
    fill: #5cae48;
    color: #5cae48;
}
.cta_add .btn-add:focus {
    box-shadow: none;
}
.cta_add .btn-add:focus-visible {
    border: none;
    outline: none;
}
.cta_add .btn-add:hover i img {
    filter: invert(54%) sepia(94%) saturate(312%) hue-rotate(63deg) brightness(93%) contrast(83%);
}

.btn-delete {
    padding: 0;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 100%;
    top: 15px;
    opacity: 0.4;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z'/%3e%3c/svg%3e")
        0 0 / 18px auto no-repeat;
}
.btn-delete:hover {
    opacity: 1;
}
.form-label ~ .btn-delete {
    top: 45px;
}

.section {
    position: relative;

    padding-right: 20px;
}
.section + .section {
    margin-top: 60px;
}
.section:first-child {
    margin-top: 20px;
}

.section .btn-delete {
    top: 35px;
    bottom: 0;
    margin: auto;
    right: -60px;
}
.section::after {
    content: '';
    position: absolute;
    right: -2px;
    top: 23px;
    bottom: 0;
    width: 1px;
    height: calc(100% - 23px);
    background: #e2e8ef;
}

@media (max-width: 767px) {
    .cta_add {
        display: block;
    }
    .cta_add .btn-add {
        margin-bottom: 10px;
    }
    .section {
        width: calc(100% - 25px);
    }
    .section .btn-delete {
        right: -50px;
    }
}

.font-18 {
    font-size: 18px;
}
.mentoring_contact {
    border: 1px solid #b5c1cb;
    border-radius: 6px;
}
.mentoring_contact p {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
}
.mentoring_contact p i {
    width: 36px;
    height: 36px;
    background: #27a7df;
    display: flex;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.mentoring_contact p i img {
    /* height: auto; */
}

.card_achievements {
    text-align: center;
}
.card_achievements .card_profiles_name {
    display: block !important;
}
.card_achievements .card_profiles_univarsity .card_profiles_name > a {
    margin: 0 auto;
    margin-bottom: -47px;
    width: 80px;
    height: 80px;
    position: relative;
    overflow: inherit; /* z-index: 9999; */
}
.card_achievements .scholarship_amt {
    margin: 0 auto;
    margin-top: 30px;
    font-size: 18px;
}
.card_achievements .card_profiles_univarsity .card_profiles_name > a span {
    background: url(../images/badge.svg) no-repeat center;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 20px;
    height: 28px;
    margin-left: -10px;
    bottom: -15px;
}
.card_achievements .card_profiles_univarsity .card_profiles_name > a img {
    border-radius: 50%;
}

/* madhu added */

.profile_picture_qr {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    min-height: 242px;
    border-radius: 10px;
    overflow: hidden;
}
.profile_picture_qr .or {
    width: 2px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.profile_picture_qr .profile_picture_section {
    background: #d42941;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: relative;
}
.profile_picture_qr .profile_picture_section:hover {
    background: #bd1f35;
}
.profile_picture_qr .profile_picture_section .block {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    z-index: 1;
}
.profile_picture_qr .qr_section {
    flex: 1;
    background: #cffed2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile_picture_qr .or span {
    background: #fff;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: absolute;
    left: -21px;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}
.profile_picture_qr .or::after {
    display: none;
}
.profile_picture_edit {
    border: 2px dashed #ffffff;
    border-radius: 74px;
    width: 145px;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 5px #d42941 inset;
    position: relative;
}
.profile_picture_edit:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    background: #fff;
    border-radius: 50%;
    z-index: 0;
}
.profile_picture_edit img {
    width: 100%;
    position: relative;
}
.profile_picture_edit .image-edit {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    height: 32px;
    background: #5cae48;
    border: 3px solid #fff;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}
.profile_picture_edit .image-edit:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    height: 12px;
    background: #fff;
    margin: auto;
}
.profile_picture_edit .image-edit:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 12px;
    height: 2px;
    background: #fff;
    margin: auto;
}
.profile_picture_edit .image-edit:hover {
    background: #669f34;
}
.profile_picture_edit_btn {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.profile_picture_edit_btn label {
    background: #ffe3e3 0% 0% no-repeat padding-box;
    border: 2px solid #ffffff;
    border-radius: 60px;
    font-size: 12px;
    font-weight: 500;
    width: 140px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.profile_picture_section:hover .profile_picture_edit_btn label {
    background: #f7b3b3;
    border: 2px solid#F7B3B3;
}
.profile_picture_edit_btn span {
    font-size: 14px;
    color: #fff;
    line-height: 18px;
}
.profile_picture_qr .qr_section p {
    font-size: 16px;
    margin: 8px 0 0;
    font-weight: 500;
}
.whatapp_text i {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background: url(../images/whatsapp.svg) no-repeat 0 0;
}
.whatapp_text {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
}
.desclamier {
    font-size: 14px;
    font-style: italic;
    margin: 10px 0 20px;
}

@media (min-width: 768px) and (max-width: 992.98px) {
    .profile_picture_qr {
        flex-direction: column;
    }
    .profile_picture_qr .profile_picture_section {
        padding: 30px;
    }
    .profile_picture_qr .qr_section {
        padding: 30px;
    }
    .profile_picture_qr .or {
        width: 100%;
    }
    .profile_picture_qr .or span {
        left: 0;
        right: 0;
    }
}

@media (max-width: 575px) {
    .profile_picture_qr {
        flex-direction: column;
    }
    .profile_picture_qr .profile_picture_section {
        padding: 30px;
    }
    .profile_picture_qr .qr_section {
        padding: 30px;
    }
    .profile_picture_qr .or {
        width: 100%;
    }
    .profile_picture_qr .or span {
        left: 0;
        right: 0;
    }
    .btn-delete {
        right: -20px;
    }
}

.success_popup {
    max-width: 700px;
}
.success_popup .img_pop {
    position: absolute;
    right: -40px;
    bottom: 0;
}
.success_popup .modal-content {
    min-height: 380px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.success_popup .modal-body {
    display: flex;
    align-items: center;
    padding-left: 60px;
}
.success_popup .brife {
    margin-top: -30px;
    max-width: 320px;
}
.success_popup .modal-body h4::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 50px;
    height: 3px;
    background: #5cae48;
    border-radius: 15px;
}
.success_popup .modal-body h4 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.success_popup .modal-footer {
    background: transparent linear-gradient(261deg, #fdfeff 0%, #eff7ff 16%, #d4ffc9 100%) 0% 0% no-repeat padding-box;
    border-radius: 90px 10px 10px 10px;
    opacity: 1;
    display: flex;
    justify-content: start;
    padding-left: 70px;
    border: 0;
    min-height: 90px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -90px;
    -webkit-border-radius: 90px 10px 10px 10px;
    -moz-border-radius: 90px 10px 10px 10px;
    -ms-border-radius: 90px 10px 10px 10px;
    -o-border-radius: 90px 10px 10px 10px;
}
.file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file > input[type='file'] {
    display: none;
}
.file > label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-radius: 6px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px dotted #8499a9;
    min-height: 60px;
    padding: 0;
    font-weight: 400;
    color: #0b3454;
}
.file > label span {
    padding-left: 5px;
    text-decoration: underline;
    color: #27a7df;
    font-weight: 400;
}
.file > label:hover {
    border-color: hsl(0, 0%, 21%);
}
.file > label:active {
    background-color: hsl(0, 0%, 96%);
}
.file > label > i {
    padding-right: 15px;
}
.file--upload > label {
}
.file--upload > label:hover {
    border-color: hsl(204, 86%, 53%);
    background-color: hsl(204, 86%, 96%);
}
.file--upload > label:active {
    background-color: hsl(204, 86%, 91%);
}
.file--uploading > label {
    color: hsl(48, 100%, 67%);
    border-color: hsl(48, 100%, 67%);
}
.file--uploading > label > i {
    animation: pulse 5s infinite;
}
.file--uploading > label:hover {
    border-color: hsl(48, 100%, 67%);
    background-color: hsl(48, 100%, 96%);
}
.file--uploading > label:active {
    background-color: hsl(48, 100%, 91%);
}
form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none;
}
.was-validated .form-select:valid:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.form-select.is-valid,
.was-validated .form-select:valid {
    color: #000;
}

.question .form-select.customSelect {
    border: 1px solid #b5c2cb;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.customChoose {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #efefef;
    border: 1px solid #b5c1cb;
    border-radius: 6px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 150%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.question .customChoose .form-control {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.customChoose.open {
    opacity: 1;
    z-index: 1;
    top: 102%;
}
.customChooseoptions {
    margin: 20px 0 20px;
    padding: 0 15px;
    list-style: none;
    max-height: 160px;
    overflow: auto;
    line-height: 16px;
    scrollbar-width: 4px;
}
/* Works on Chrome, Edge, and Safari */
.customChooseoptions::-webkit-scrollbar {
    width: 4px;
}
.customChooseoptions::-moz-scrollbar {
    width: 4px;
}
.customChooseoptions::-webkit-scrollbar-track {
    background: #eee;
}
.customChooseoptions::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #eee;
}
.customChooseoptions li {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}
.customChooseoptions li:hover {
    color: #5cae48;
}
.customChooseoptions li:not(:last-child) {
    margin-bottom: 12px;
}
.customInputSearch + .customChoose.open,
.customInputSearch:focus + .customChoose {
    opacity: 1;
    top: 102%;
    z-index: 1;
}

.input-group-customSelect {
    width: 33.3%;
}
.input-group .customSelect {
    border-radius: 6px 0 0 6px !important;
}
.input-group-customSelect:not(:first-child) label {
    border-radius: 0 !important;
}
.form-select.CurrencySelect.customSelect ~ .customChoose {
    width: 168px;
    border-radius: 6px !important;
}

#login_pop .signup,
#sociallogin,
.bg_side_blue .signup {
    background: #0b3454;
    margin-right: -1px;
    border-radius: 0px 18px 18px 80px;
}
#login_pop .signup h1 {
    font-family: 'Jost', sans-serif;
    color: #fff;
}
#login_pop .signup:before {
    display: none;
}
#login_pop .signup .title {
    position: relative;
}
#login_pop .innercover {
    max-width: 385px;
    width: 90%;
    margin: auto;
    padding: 0 20px;
}
#login_pop .innercover .img {
    display: flex;
    height: auto;
    align-items: flex-end;
    margin-bottom: 37px;
    justify-content: center;
}
.innercover .img img {
    max-width: 200px;
}
.icon.icon-correct::before {
    content: '';
    width: 13px;
    height: 7px;
    border-left: 2px solid #98bbdf;
    border-bottom: 2px solid #98bbdf;
    display: inline-block;
    transform: rotate(-40deg);
    margin: 7px 3px 0 0;
    vertical-align: top;
}
.signup form .form-check-label {
    cursor: pointer;
}
.form-group label[onclick] {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.form-group.form-control-email::before {
    width: 16px;
    height: 12px;
    content: '';
    background: url(../images/form-email.svg) no-repeat 0 center;
    position: absolute;
    top: 15px;
    bottom: auto;
    margin: auto;
    left: 16px;
    z-index: 2;
}
.form-group.form-control-password::before {
    width: 12px;
    height: 16px;
    content: '';
    background: url(../images/form-lock.svg) no-repeat 0 center;
    position: absolute;
    top: 15px;
    bottom: auto;
    margin: auto;
    left: 19px;
    z-index: 2;
}
/* .form-group.form-control-mobile::before {
    width: 9px;
    height: 16px;
    content: '';
    background: url(../images/form-cell-phone.svg) no-repeat 0 center;
    position: absolute;
    top: 15px;
    bottom: auto;
    margin: auto;
    left: 16px;
    z-index: 2;
} */
.form-group label[onclick]:hover,
.signup form .form-check-label[for='exampleCheck1']:hover,
.signup a:hover {
    color: #5cae48 !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}
form a:hover {
    text-decoration: none;
}
.form-group label[onclick]:hover {
    color: #5cae48 !important;
}
.signup form .form-check-input:not(#exampleCheck1):checked + label.form-check-label {
    background: #217aff !important;
    border-color: #217aff !important;
    color: #fff;
}
.signup form .form-check-input:checked + label .icon.icon-correct::before {
    border-color: #fff !important;
}
.signup form .form-control {
    min-height: 40px;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    color: #000;
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    z-index: 1;
    position: relative;
}
.signup #existing-user form .form-control {
    padding-left: 50px;
}
.signup #existing-user form .form-control#ShopLoginFormModel_email {
    background: #fff url(../images/form-email.svg) no-repeat 16px center;
}
/* .signup #existing-user form .form-control#ShopLoginFormModel_mobile {
    background: #fff url(../images/form-cell-phone.svg) no-repeat 16px center;
} */
.email-text {
    color: #ffffff99;
}
.signup #existing-user form .form-control#ShopLoginFormModel_password,
.signup #existing-user form .form-control#ShopSignupFormModel_password {
    background: #fff url(../images/form-lock.svg) no-repeat 19px center;
}
.signup #existing-user form .form-control#ShopSignupFormModel_first_name,
/* .signup #existing-user form .form-control#ShopSignupFormModel_last_name {
    background: #fff url(../images/form-user.svg) no-repeat 19px center;
} */
/* .signup #existing-user form .form-control#ShopSignupFormModel_present_class {
    background: #fff url(../images/form-measure.svg) no-repeat 16px center;
} */
/* .signup #existing-user form .form-control#ShopSignupFormModel_board_id {
    background: #fff url(../images/form-open-book.svg) no-repeat 16px center;
} */
/* .signup #existing-user form .form-control#ShopSignupFormModel_country_code {
    background: #fff url(../images/form-globe.svg) no-repeat 16px center;
} */
/* .signup #existing-user form .form-control#ShopSignupFormModel_mobile_number {
    background: #fff url(../images/form-cell-phone.svg) no-repeat 16px center;
} */
.signup form .form-group {
    position: relative;
}
.signup form .form-group .viewpassword {
    position: absolute;
    right: 15px;
    bottom: auto;
    top: 14px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 2;
}
.signup form .form-control + .viewpassword::before {
    content: '';
    width: 0%;
    height: 2px;
    background: #022d6d;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.signup form .form-control[type='text'] + .viewpassword::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.signup form .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
.signup form .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.signup form .form-check-input[type='checkbox'] {
    border-radius: 0.25em;
}
.signup form .form-check-input:checked {
    background-color: #7fb507;
    border-color: #7fb507;
}
.signup form .form-check-input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.signup .owl-dots .owl-dot {
    box-shadow: 0 0 0 0;
}
.signup .owl-theme .owl-dots .owl-dot.active span,
.signup .owl-theme .owl-dots .owl-dot:hover span {
    background: #585858;
    opacity: 0.8;
}
.login-footer {
    margin: 50px 0;
}
.login-footer p {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    position: relative;
}
.login-footer p::after {
    background: #c5c5c5;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 7px;
    margin: auto;
    content: '';
    left: 0;
    right: 0;
}
.login-footer p b {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    position: relative;
    z-index: 2;
    background: #fff;
    padding-right: 15px;
}
.login-footer > span {
    color: #060606;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
}
.signup .or {
    margin: 0 0 20px;
    text-align: center;
    display: inline-block;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    position: relative;
}
#existing-user.active ~ div .btn-signin {
    display: none;
}
.input-otp {
    max-width: 60px;
    width: 100%;
    outline: none;
    box-shadow: none;
}
#new-user.active ~ div .btn-signup {
    display: none;
}
#new-user .nav-pills .nav-link {
    background: #fff;
    width: 100%;
}
#new-user .nav-pills .nav-link.active {
    background: #217aff;
}
#new-user ul.nav-pills li {
    width: 30%;
}
#new-user .nav-pills .nav-link.active .icon.icon-correct::before {
    border-color: #fff !important;
}
#pills-others.active + div {
    display: none;
}
.or::after {
    background: #38566e;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 7px;
    margin: auto;
    content: '';
    left: 0;
    right: 0;
    max-width: 463px;
}
.signup .or b {
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    background: #0b3454;
    position: relative;
    z-index: 2;
    padding: 0 10px;
}
.sociallnks {
    text-align: center;
}
.sociallnks p {
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    font-family: 'Jost', sans-serif;
    margin: 0;
    font-weight: 400;
}
.sociallnks p a {
    color: #a2e609;
    font-weight: 500;
}
.sociallnks p a:hover {
    text-decoration: none;
}
.sociallinks ul {
    list-style: none;
}
.sociallnks ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
}
.sociallnks ul li a {
    background: #ffffff;
    box-shadow: 0px 5px 10px #0000001a;
    border-radius: 18px;
    width: 100%;
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
}
.sociallnks ul li {
    width: 100px;
    margin: 20px 10px;
}
.signup form .gendar-radio-button + label {
    float: left;
    padding: 0.5em 1em;
    cursor: pointer;
    border: 1px solid #28608f;
    margin-right: -1px;
    color: #000;
    background-color: #ffffff;
    width: 100%;
    border-radius: 6px;
    min-height: 40px;
    display: flex;
    align-items: center;
    border: none;
}
.signup form .error .error-valid-text {
    color: #fff !important;
    margin-top: 3px;
}
.signup form .error .form-control {
    background-color: #f7d7d7 !important;
    border: 1px solid #fd7373;
}
/* .signup form .gendar-radio-button + label[for='maleBtn'] {
    background: #fff url(../images/form-male_blue.svg) no-repeat 16px center;
    padding-left: 50px;
} */
/* .signup form .gendar-radio-button + label[for='femaleBtn'] {
    background: #fff url(../images/form-female_blue.svg) no-repeat 16px center;
    padding-left: 50px;
} */
/* .signup form .gendar-radio-button:checked + label[for='maleBtn'] {
    background: #1074e9 url(../images/form-male_white.svg) no-repeat 16px center;
} */
/* .signup form .gendar-radio-button:checked + label[for='femaleBtn'] {
    background: #1074e9 url(../images/form-female_white.svg) no-repeat 16px center;
} */
.signup form .gendar-radio-button + label:first-of-type {
}
.signup form .gendar-radio-button + label:last-of-type {
}
.signup form .gendar-radio-button:checked + label {
    background-color: #1074e9;
    color: #fff;
}
.btn-access {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
#new-user .progress {
    height: 6px;
    background: #051c3e;
}
#new-user a#but_add {
    cursor: pointer;
}
#myloginTab {
    display: flex;
}
#myloginTab li {
    flex: 2;
}
#myloginTab li button {
    width: 100%;
    border: 0;
    border-radius: 0;
    line-height: 70px;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Jost', sans-serif;
    background: #04243c;
    color: rgba(255, 255, 255, 0.5);
}
#myloginTab li button.active {
    background: #0b3454;
    color: #fff;
}
.daterangepicker .calendar-table th {
    background-image: none;
}
.daterangepicker .calendar-table .next span {
    transform: rotate(130deg);
    -webkit-transform: rotate(130deg);
}

.page_loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5;
}
.page_loader .sr-only {
    font-size: 0;
}
.page_loader .spinner-border {
    border-color: #fff #fff #fff transparent;
    width: 100px;
    height: 100px;
    border-width: 10px;
}

.css-b62m3t-container .css-t3ipsp-control,
.css-b62m3t-container,
.css-13cymwt-control {
    height: 43px !important;
    border: 0 !important;
}
div.css-9kqgro-control,
div.css-xpesdl-control,
div.css-9kqgro-control {
    min-height: 38px !important;
    margin-top: 1px;
    margin-right: 1px;
}
div.css-1fdsijx-ValueContainer {
    padding: 0;
}
div.css-3w2yfm-ValueContainer {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-x: auto;
    scrollbar-width: thin;
}

div.css-3w2yfm-ValueContainer::-webkit-scrollbar {
    height: 3px;
}
div.css-3w2yfm-ValueContainer::-moz-scrollbar {
    height: 3px;
}
div.css-3w2yfm-ValueContainer::-webkit-scrollbar-track {
    background: #ccc;
}
div.css-3w2yfm-ValueContainer::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
div.css-1p3m7a8-multiValue {
    display: inline-flex;
}
.delete-sticky {
    position: sticky;
    top: 147px;
    right: 0;
    text-align: right;
    cursor: pointer;
}
.blue-tick {
    color: blue;
}
.header_container img {
    height: 100%;
    width: auto;
    max-height: 42px;
}

.school_logo {
    max-height: 70px;
}

.alumni-welcome-image-logo {
    height: 60px;
    border-radius: 50%;
    width: 60px;
}

.btn-blue {
    background: #0b3c54;
    border-radius: 27px;
    height: 40px;
    padding: 0 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
}

.relevantAlumniMatch .btn-complete-now {
    border: 1px solid #d42941;
    border-radius: 25px;
    width: 160px;
    height: 40px;
    background: #fff;
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin-right: 23px;
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.sucess-message {
    color: white !important;
}
.error-message {
    color: red !important;
}

.mention-dropdown {
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.mention-option {
    padding: 8px 12px;
    cursor: pointer;
}

.mention-option:hover {
    background-color: #f0f0f0;
}

.triangleGraph {
    position: relative;
    clip-path: polygon(0px 0px, 100% 0px, 60% 100%, 40% 100%);
    max-width: 380px;
    width: 50%;
}

.triangleGraph ul {
    padding: 0;
    margin: 0;
}

.triangleGraph ul li span {
    color: #fff;
}

.triangleGraph ul li {
    background: #ff9f40;
    height: 65px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.triangleGraph ul li + li {
    background: #147ad6;
}

.triangleGraph ul li + li + li {
    background: #4bc0c0;
}

.triangleGraph ul li + li + li + li {
    background: #fd6284;
}

.triangleGraph ul li + li + li + li + li {
    background: #fecb5e;
}

.triangleGraph ul li + li + li + li + li + li {
    background: #e56ac1;
}

.triangleGraph ul li:not(:last-child) {
    margin-bottom: 2px;
}

.triangleGraphLabel ul {
    padding: 0;
    margin: 0;
    text-align: right;
    line-height: 18px;
    list-style: none;
    font-weight: 500;
    font-size: 16px;
}

.triangleGraphLabel ul li {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.triangleGraphLabel ul li:not(:last-child) {
    margin-bottom: 2px;
}

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}

.flex-1 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.radius {
    border-radius: 25px !important;
}

.radius-20 {
    border-radius: 20px !important;
}

.blue {
    background: #2680EB !important
}

.green {
    background: #9ED523 !important
}

.gray {
    background: #F1F1F1 !important
}

.dark-gray {
    background: #ececec !important
}

.btn-completed {
    background: #9ED523;
    color: #fff;
    border-color: #9ED523;
}

.btn-completed:hover {
    background: #9ED523;
    color: #fff;
    border-color: #9ED523;
}

.btn-white {
    background: #fff;
    color: #000;
    border-color: #fff;
}

.btn-white:hover {
    background: #f1f1f1;
    color: #000;
    border-color: #f1f1f1;
}

.btn-outter-line {
    border: 1px solid #000;
}

.btn-outter-line:hover {
    border: 1px solid #000;
}

.btn-nostyle {
    border: 0;
    background: none;
    padding: 0;
}

.btn-video,
.btn-video:hover {
    background: #FFF;
    color: #2680EB;
    line-height: 20px;
}

.btn-dashboard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #414141;
    border-radius: 27px;
    text-align: center;
    justify-content: center;
    min-width: 160px;
}

.btn .icon {
    font-size: 14px;
    margin-right: 7px;
}

.white {
    background: #fff;
}

.dark-blue {
    background: #185094;
}

.border-darker-blue {
    border-color: #114798 !important;
}

.darker-blue {
    background-color: #001D48;
}

.orange {
    background-color: #FFA824;
}

.dark-green {
    background-color: #3BB54A;
}

.text-black {
    color: #000;
}

.text-orange {
    color: #FFA824;
}

.text-white {
    color: #fff;
}

.text-green {
    color: #3BB54A;
}

.text-blue {
    color: #2680EB;
}

.text-dark-blue {
    color: #023378;
}

.text-dark-gray {
    color: #515151;
}

.text-lite-gray {
    color: #999;
}

.font-10 {
    font-size: 10px !important;
}

.font-11 {
    font-size: 11px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-17 {
    font-size: 17px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-19 {
    font-size: 19px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-26 {
    font-size: 26px !important;
}

.font-28 {
    font-size: 28px;
}

.font-30 {
    font-size: 30px;
}

.font-32 {
    font-size: 32px;
}

.font-34 {
    font-size: 34px;
}

.font-36 {
    font-size: 36px;
}

.font-100 {
    font-weight: 100
}

.font-200 {
    font-weight: 200
}

.font-300 {
    font-weight: 300
}

.font-400 {
    font-weight: 400
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}
.font-900 {
    font-weight: 900;
}

body {
    background: #F5F5F5;
    background-size: cover;
}

.max-700{max-width: 800px;width: 90%;}
.main-wrapper {    height: 100vh;position: relative;}
.strip {position: absolute;left: 0;right: 0;top: 0;height: 4px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;}
.strip i {width: 20%;background: #51499C;}
.strip i + i{background: #26A7DF;}
.strip i + i + i{background: #D42941;}
.strip i + i + i + i{background: #F05A2A;}
.strip i + i + i + i +i{background: #F8B018;}

.rate_container {box-shadow: 0px 0px 10px 0px #92929259;}
.experienceboxheading {background: #0B3454;border-radius: 4px 4px 0 0;padding: 20px;position: relative;overflow: hidden;}
.experienceboxheading:before {content: '';position: absolute;top: 0;bottom: 0;left: 0;width: 131px;}
.experienceboxheading::after {content: '';position: absolute;top: 0;bottom: 0;right: 0;width: 84px;}
.experienceboxheading h3 {font-size: 30px;line-height: 40px;margin-bottom: 0px;text-align: center;color: #fff;position: relative;}
.experienceboxheading p {font-size: 16px;line-height: 23px;text-align: center;color: #fff;margin: 0;position: relative;}
.experiencebox {background: #fff;border-radius: 0 0 4px 4px;position: relative;box-shadow: 0px 0px 50px #0000001A;}

.half-gray {position: relative;}
.half-gray:before {content: '';position: absolute;left: 0;bottom: 0;top: 0;width: 50.5%;background: #F3F3F3;border-radius: 6px 0px 0px 6px;}
.half-gray > div {position: relative;}

.messages {flex: 1;display: flex;flex-direction: column;}
.leftUser {align-items: end;}
.leftUser p {background: #2296C9;border-radius: 10px 10px 0px 10px;padding: 7px 20px;color: #fff;margin-bottom: 10px;}
.rightUser {align-items: start;}
.rightUser p {background: #413A7D 0% 0% no-repeat padding-box;border-radius: 10px 10px 10px 0px;opacity: 1;padding: 7px 20px;color: #fff;margin-bottom: 10px;}
.conversation .userimg {display: flex;align-items: start;vertical-align: top;}
.conversation {height: 310px;overflow-y: scroll;overflow-x: hidden;margin-right: -8px;}

 /* width */
.conversation{overflow-y: scroll;scrollbar-width: thin;}
.conversation::-webkit-scrollbar {width: 3px;}
/* Track */
.conversation::-webkit-scrollbar-track {background: #f1f1f1;}

/* Handle */
.conversation::-webkit-scrollbar-thumb {background: #888;}

/* Handle on hover */
.conversation::-webkit-scrollbar-thumb:hover {background: #555;} 


@media (max-width: 575px) {
.experienceboxheading h3 {font-size: 22px;}
.modal-content.half-gray {border: 0;}
.modal-header.conversation-header {padding: 0;}
.modal-header.conversation-header .left-col {padding: 15px 20px;background: #413a7d;}
.modal-header.conversation-header .left-col .text-dark-blue {color: #fff;}
.modal-header.conversation-header .right-col {padding: 15px 20px;background: #2296c9;}
.modal-header.conversation-header .right-col .text-dark-blue {color: #fff;}
}
.milestoneSection .white-block {
    padding: 15px;
}

.milestone {
    background: #fff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    height: 100%;
    position: relative;
    padding: 30px;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.milestone .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -33px;
    background: #d42941;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.milestone .icon span {
    font-size: 32px;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.milestone .icon span:before {
    color: #fff;
}

.milestone .count {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
}

.milestone .btn,
.activity-btn {
    height: 40px;
    text-align: center;
    margin: auto;
    display: inline-block;
    padding: 0 25px;
    border-radius: 25px;
    line-height: 36px;
    overflow: hidden;
    position: relative;
}

.milestone .btn span,
.btn-green span,
.activity-btn span {
    position: relative;
}

.milestone .count {
    font-size: 22px;
    font-weight: 600;
    position: absolute;
    left: 15px;
    top: 12px;
}

.milestone h3 {
    text-align: center;
    width: calc(100% - 34px);
    margin: 10px auto 0;
    font-size: 20px;
    font-weight: 600;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
}

.milestone .progress-status {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 16px 0 0;
}

.milestone .progress-status li {
    width: 40px;
    height: 3px;
    background: #dedede;
    display: inline-block;
    margin: 0 7px;
}

.milestone .progress-status li.completed {
    background: #5cae48;
}

.milestoneProgressGraph {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}

.milestoneGraph {
    min-height: 260px;
    width: 16%;
}

.milestoneGraph .progress {
    width: 30%;
    height: auto;
}

#dashboard-pills-tabContent .milestoneGraph h3 {
    font-size: 16px;
    margin: 0 0 10px;
}

#milestoneProgress-collapseThree .graph-result-list ul li:last-child {
    margin-right: 0;
}

.icon-user-invert:before {
    content: '\e940';
}

.icon-user:before {
    content: '\e941';
}

.btn.btn-complete-now {
    background: #0b3454;
    color: #fff;
}

.btn.btn-complete-now::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #185584;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn.btn-complete-now:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn.btn-complete-now:hover {
    color: #fff;
}

.btn.btn-completed {
    background: #5cae48;
    color: #fff;
}

.icn_details .btn-green {
    width: 140px;
}

.icn_details .btn-green span {
    font-weight: 500;
    font-size: 16px;
}

.icn_details .btn-green span.icon-correct::before {
    font-size: 13px;
}

.icn_details {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 25px 30px;
}

.iconsec {
    height: 50px;
    width: 50px;
    background: #d42941;
    border-radius: 50%;
}

.iconsec > b {
    color: #fff;
    font-size: 23px;
}

.iconsec_title {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0 0 8px;
}

.iconsec_cont {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    display: none;
    margin: 0 0 8px;
}

.iconsec_link {
    text-decoration: underline;
    color: #147ad6;
    font-size: 14px;
}

.iconsec_link:hover {
    text-decoration: none;
}

.iconsec_link .readmore {
    display: block;
}

.iconsec_link .readless {
    display: none;
}

.iconsec_link.active .readmore {
    display: none;
}

.iconsec_link.active .readless {
    display: block;
}

.profile_setup_help {
    width: 100%;
    max-width: 304px;
    height: auto;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 30px;
    text-align: center;
}

.profile_setup_help > p:first-child {
    width: 100%;
    margin: auto;
    text-align: center;
    max-width: 190px;
}

.float_horizontal_animate {
    transform: translatex(0px);
    animation: float_horizontal 4s ease-in-out infinite;
}

.btn-green {
    background: #5cae48;
    border-radius: 27px;
    height: 40px;
    padding: 0 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
}

.btn-green:hover {
    color: #eeffea;
}

.btn-darkblue {
    background: #0b3454;
    border-radius: 27px;
    height: 40px;
    padding: 0 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
}

.btn-darkblue:hover,
.btn-green-msg:hover,
.btn-pink-msg:hover {
    color: #fff;
}

.btn-darkblue span,
.btn-green-msg span,
.btn-pink-msg span {
    position: relative;
}

.btn-darkblue::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #08273f;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-darkblue:hover::before,
.btn-green-msg:hover::before,
.btn-pink-msg:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
